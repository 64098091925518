import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

// TODO Dont think these are h3 really, its not structurally correct, and needs better association
const RecurringPaymentHeader: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <div className="recurring-payment__header">
      <div className="row">
        <div className="col-lg-4">
          <h3 className="jg-h6">
            {t('recurringPaymentsComponent.header.cause')}
          </h3>
        </div>
        <div className="col-md-3">
          <h3 className="jg-h6">
            {t('recurringPaymentsComponent.header.nextpayment')}
          </h3>
        </div>
        <div className="col-md-3">
          <h3 className="jg-h6">
            {t('recurringPaymentsComponent.header.amount')}
          </h3>
        </div>
      </div>
    </div>
  );
};

export default RecurringPaymentHeader;
