// shrinked version of https://github.com/tannerjt/state_images.json/blob/master/states.js

const stateMap = [
  { name: 'Australian Capital Territory', isoCode: 'ACT' },
  { name: 'New South Wales', isoCode: 'NSW' },
  { name: 'Queensland', isoCode: 'QLD' },
  { name: 'South Australia', isoCode: 'SA' },
  { name: 'Western Australian', isoCode: 'WA' },
  { name: 'Victoria', isoCode: 'VIC' },
  { name: 'Northern Territory', isoCode: 'NT' },
  { name: 'Tasmania', isoCode: 'TAS' },
]
  // eslint-disable-next-line no-nested-ternary
  .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));

export default stateMap;

export const getStateByIsoCode = isoCode => {
  if (isoCode === null || !isoCode) return undefined;
  return stateMap.find(state => state.isoCode === isoCode);
};

export const getAUStateNameByIsoCode = isoCode => {
  const state = getStateByIsoCode(isoCode);
  return state ? state.name : undefined;
};

export const getStateByName = stateName => {
  if (stateName === null || !stateName) return undefined;
  return stateMap.find(
    state => state.name.toLowerCase() === stateName.toLowerCase(),
  );
};

export const getIsoCodeByStateName = stateName => {
  const state = getStateByName(stateName);
  return state ? state.isoCode : undefined;
};
