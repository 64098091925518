import { ActionTypes as types } from 'constants';

const initialState = {
  donation: {},
  isLoaded: false,
  isFetching: false,
};

const donationSummary = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.FETCH_DONATION_SUMMARY:
      return Object.assign({}, state, {
        isLoaded: false,
        isFetching: true,
      });
    case types.FETCH_DONATION_SUMMARY_SUCCESS:
      return Object.assign(
        {},
        {
          donation: action.payload,
          isLoaded: true,
          isFetching: false,
        },
      );
    case types.FETCH_DONATION_SUMMARY_FAILURE:
      return Object.assign({}, state, {
        isLoaded: false,
        isFetching: false,
      });
    default:
      return state;
  }
};

export default donationSummary;
