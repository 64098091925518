import { ActionTypes as types } from 'constants';

const initialState = {
  donations: [],
  totalResults: null,
  next: undefined,
  isLoaded: false,
  isFetching: false,
  charityDonationsOnly: false,
  date: {
    startDate: null,
    endDate: null,
  },
};

// TODO: replace Object.assign with some deepMerge/clone lib
const donationHistory = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.FETCH_DONATION_HISTORY:
      return Object.assign({}, state, {
        isLoaded: false,
        isFetching: state.next === undefined || action.payload.clearData,
      });
    case types.FETCH_DONATION_HISTORY_SUCCESS: {
      const { results, clearData, ...rest } = action.payload;

      const donationsList = !clearData
        ? state.donations.concat(results)
        : results;

      return Object.assign(
        {},
        {
          donations: donationsList,
          ...rest,
        },
        {
          isLoaded: true,
          isFetching: false,
        },
      );
    }
    case types.FETCH_DONATION_HISTORY_FAILURE:
      return Object.assign({}, state, {
        isLoaded: false,
        isFetching: false,
      });
    default:
      return state;
  }
};

export default donationHistory;
