import checkLogin from '../setters/checkLogin';
import config from '../../config';
import { ActionTypes } from '../../constants';

export default () => async (dispatch, _getState, auth) => {
  await dispatch(checkLogin());
  dispatch({ type: ActionTypes.UNLINK_FACEBOOK });

  try {
    const { guid } = auth.getAccessToken();
    const { accessToken } = auth.getParsedAuthCookie();
    const url = `${config.iam.baseUrl}/users/${guid}/facebook/unlink`;
    const options = {
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${accessToken}`,
      }),
      method: 'POST',
    };
    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error(`Facebook unlink fail status:${response.status}`);
    }
    dispatch({ type: ActionTypes.UNLINK_FACEBOOK_SUCCESS });
    return Promise.resolve();
  } catch (error) {
    dispatch({ type: ActionTypes.UNLINK_FACEBOOK_FAILURE });
    return Promise.reject();
  }
};
