/* eslint-disable jsx-a11y/no-static-element-interactions, react/prop-types */
import './Personal.scss';
import React from 'react';
import { PropTypes } from 'prop-types';
import { paths } from 'constants';
import { track } from 'utils/koala-analytics';
import { Trans } from 'react-i18next';
import { getDecodedToken } from 'utils/getDecodeToken';
import config from 'config';

const propTypes = {
  address: PropTypes.string,
};

const defaultProps = {
  address: undefined,
};

const trackEditEmail = () => {
  const trackingData = {
    event: 'link click',
    subtype: 'hyperlink',
    page_section: 'personal details > email',
    event_value: 'edit',
  };
  track(trackingData);
};
const decodedToken = getDecodedToken();
const isAuthSrcBB = decodedToken && decodedToken.auth_src === 'BB';

const Email = props => (
  <section className="details-block email">
    <div className="jg-space-phsm@sm jg-space-phn@lg">
      <div className="jg-text--medium-light">
        <Trans i18nKey="personalDetails.email">Email</Trans>
        <br />
      </div>
      <div className="jg-text--dark">
        <span className="data-value">{props.address}</span>
        <a
          href={
            config.features.isEnabledBBID && isAuthSrcBB
              ? 'https://signin.blackbaud.com/userprofile/'
              : `${config.baseUrl}${config.basename}${paths.Email}`
          }
          onClick={trackEditEmail}
          className="jg-edit-link qa-link__email jg-space-mlsm"
          target={
            config.features.isEnabledBBID && isAuthSrcBB ? '_blank' : undefined
          }
          rel="noreferrer"
        >
          <Trans i18nKey="personalDetails.edit">Edit</Trans>
          {config.features.isEnabledBBID && isAuthSrcBB && (
            <span className="external-link__icon">
              <svg fill="none" viewBox="0 0 12 12" width={12} height={12}>
                <path
                  fill="#818B99"
                  d="M7.333 0v1.333h2.394L3.173 7.887l.94.94 6.554-6.554v2.394H12V0m-1.333 10.667H1.333V1.333H6V0H1.333C.593 0 0 .6 0 1.333v9.334A1.333 1.333 0 0 0 1.333 12h9.334A1.334 1.334 0 0 0 12 10.667V6h-1.333v4.667Z"
                />
              </svg>
            </span>
          )}
        </a>
      </div>
    </div>
  </section>
);

Email.propTypes = propTypes;

Email.defaultProps = defaultProps;

export default Email;
