import { collectionLocationId, ActionTypes as types } from 'constants';
import { getFromPreferencesServiceApi, logError } from 'actions';
import { updateFetchAppError } from 'actions/setters/updateAppErrors';

const log = { component: 'getUserNotifications' };

// eslint-disable-next-line max-len
export default (
  _getFromPreferencesServiceApi = getFromPreferencesServiceApi,
  _logError = logError,
) => (dispatch, getState) => {
  const { isoCountryCode, locale } = getState().app.localeData;

  dispatch({
    type: types.FETCH_CONSENT_STATEMENTS,
  });

  const combinedLocale =
    locale.indexOf('-') !== -1 ? locale : `${locale}_${isoCountryCode}`;
  const notificationsResource = `/ConsentStatements/${collectionLocationId}/${combinedLocale}`;

  return dispatch(_getFromPreferencesServiceApi(notificationsResource))
    .then(([[res, json]]) => {
      if (res.ok) {
        dispatch({
          type: types.FETCH_CONSENT_STATEMENTS_SUCCESS,
          payload: json,
        });
      } else {
        dispatch(updateFetchAppError());
        dispatch({ type: types.FETCH_CONSENT_STATEMENTS_FAILURE });
        dispatch(
          _logError(types.UPDATE_APP_STATUS_FETCH_ERROR, { ...log, res }),
        );
      }
    })
    .catch(error => {
      dispatch(updateFetchAppError());
      dispatch({ type: types.FETCH_CONSENT_STATEMENTS_FAILURE });
      dispatch(
        _logError(types.UPDATE_APP_STATUS_FETCH_ERROR, { ...log, error }),
      );
    });
};
