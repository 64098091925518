import { ActionTypes as types } from 'constants';

const initialState = {
  data: {},
  isLoaded: false,
  isFetching: false,
};

const updateData = (state, updatedData) =>
  Object.assign({}, state.data, updatedData);

const user = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.FETCH_USER:
      return Object.assign({}, state, {
        isLoaded: false,
        isFetching: true,
      });
    case types.FETCH_USER_SUCCESS:
      return {
        data: { ...action.payload },
        isLoaded: true,
        isFetching: false,
      };
    case types.FETCH_USER_FAILURE:
      return Object.assign({}, state, {
        isLoaded: false,
        isFetching: false,
      });
    case types.UPDATE_USER:
      return Object.assign({}, state, {
        data: updateData(state, action.payload),
      });
    case types.UNLINK_FACEBOOK_SUCCESS:
      return {
        ...state,
        data: { ...state.data, HasFacebookCredentials: false },
      };
    default:
      return state;
  }
};

export default user;
