export { default as auth } from './auth';
export { default as getBearerHeaders } from './getBearerHeaders';
export { default as FormUtils } from './FormUtils';
export { default as validators } from './validation';
export { default as humanizedDate } from './humanizedDate';
export { default as createErrorKey } from './createErrorKey';
export { default as getPaymentCardIcon } from './paymentCardIcons';
export { getCurrencySymbol, getCurrencyLocale } from './currencyUtils';
export * from './stringUtils';

// TODO: missing function
export const logError = () => {
  /* console.error('Error'); */
};
