import { connect } from 'react-redux';
import { getUser, getUserAddress, koalaAnalyticsActions } from 'actions';
import appSelectors from 'selectors/app-selectors';
import PersonalDetailsComponent from 'pages/Personal/PersonalDetailsComponent';
import { getStateNameByIsoCode } from 'i18n/us-states';
import { getAUStateNameByIsoCode } from 'i18n/au-states';

const fetchData = dispatch =>
  Promise.all([dispatch(getUser()), dispatch(getUserAddress())]);

const transformAddressForDisplay = address => ({
  ...address,
  State:
    address.CountryCode === 'AU'
      ? getAUStateNameByIsoCode(address.State)
      : getStateNameByIsoCode(address.State),
});

export const mapStateToProps = state => ({
  user: state.app.user.data,
  userAddress: transformAddressForDisplay(state.app.userAddress.data),
  status: {
    isFetching: appSelectors.isPersonalDetailsFetching(state),
    fetchError: state.app.appErrors.fetchError,
  },
  localeData: state.app.localeData,
});

export const mapDispatchToProps = dispatch => ({
  fetchData: () => {
    fetchData(dispatch);
  },
  trackPageView: (subtype, pageSection) => {
    koalaAnalyticsActions.trackPageView(subtype, pageSection)(dispatch);
  },
});

const PersonalDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PersonalDetailsComponent);

PersonalDetailsContainer.fetchData = fetchData;

export default PersonalDetailsContainer;
