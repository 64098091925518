import React, { useEffect } from 'react';

import { AsyncPageProfile } from '../../pages/AsyncPage';
import ErrorPage from '../ErrorPage/ErrorPage';
import GlobalAlert from '../GlobalAlert/GlobalAlert';
import { Layout } from '../Layout/Layout';
import { PrivateRoute } from '../Routes/PrivateRoute';
import { Switch } from 'react-router-dom';
import { ToastProvider } from '@justgiving/toast';
import componentDidCatch from '../../utils/componentDidCatch';
import config from '../../config';
import { getRoutes } from '../../routes';
import { hot } from 'react-hot-loader/root';
import { paths } from '../../constants';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { withErrorBoundary } from '@justgiving/error-boundary';

export const App = () => {
  const { location } = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className="jg-eua-app">
      <GlobalAlert />
      <ToastProvider>
        <Switch>
          {config.features.showProfilePage && (
            <PrivateRoute path={paths.Index} exact>
              <AsyncPageProfile />
            </PrivateRoute>
          )}
          <Layout routes={getRoutes(t)} />
        </Switch>
      </ToastProvider>
    </div>
  );
};

export default hot(withErrorBoundary(App, <ErrorPage />, componentDidCatch));
