import { checkLogin } from 'actions';
import getBearerHeaders from 'utils/getBearerHeaders';
import withRetry from 'actions/api/withRetry';

const headers = getBearerHeaders();

export default url => dispatch =>
  dispatch(checkLogin()).then(() => {
    headers.set(
      'Accept',
      'application/hal+json; charset=utf-8; version=1.0; profile=jg.donationcollection',
    );
    headers.set(
      'Prefer',
      'return=representation,embed="*|IncludeSelf,NoLinkTransform"',
    );

    const fetchPromise = () =>
      fetch(url, {
        method: 'GET',
        headers,
        credentials: 'include',
      }).then(res => Promise.all([res, res.json()]));

    return withRetry(fetchPromise);
  });
