import React from 'react';

interface SettingSectionProps {
  actions: {
    onBtnClick: () => void;
  };
  content: {
    heading: string;
    image: {
      url: string;
      alt: string;
    };
    description: string;
    btnLabel: string;
  };
}

export function SettingSection({
  content: {
    heading,
    image: { url, alt },
    description,
    btnLabel,
  },
  actions: { onBtnClick },
}: SettingSectionProps) {
  return (
    <section className="app-settings jg-space-mvlg">
      <h2 className="jg-h4">{heading}</h2>
      <div className="app-settings__row">
        <div className="app-settings__logo">
          <img src={url} width="48" height="48" alt={alt} />
        </div>
        <div className="app-settings__content">
          {description}
          <button
            className="app-settings__button jg-text--link"
            onClick={onBtnClick}
            type="button"
          >
            {btnLabel}
          </button>
        </div>
      </div>
    </section>
  );
}
