import 'isomorphic-fetch';

// Api
export { default as getFromApi } from 'actions/api/getFromApi';
export { default as getFromDonationApi } from 'actions/api/getFromDonationApi';
export { default as getFromPSApi } from 'actions/api/getFromPSApi';
export { default as getFromIamApi } from 'actions/api/getFromIamApi';

export { default as getFromProfileApi } from 'actions/api/getFromProfileApi';
export { default as getFromPreferencesServiceApi } from 'actions/api/getFromPreferencesServiceApi';
export { default as getFromDonationServiceApi } from 'actions/api/getFromDonationServiceApi';
export { default as getFromDonationHistoryServiceApi } from 'actions/api/getFromDonationHistoryServiceApi';
export { default as getFromWalletServiceApi } from 'actions/api/getFromWalletServiceApi';
export { default as submitForm, sendToApi } from 'actions/api/submitForm';
export { default as submitFormToIam } from 'actions/api/submitFormToIam';
export { default as submitFormToProfile } from 'actions/api/submitFormToProfile';
export { default as submitFormToPreferencesService } from 'actions/api/submitFormToPreferencesService';

// Getters
export { default as findAddresses } from 'actions/getters/findAddresses';
export { default as getNotifications } from 'actions/getters/getNotifications';
export { default as getUserNotifications } from 'actions/getters/getUserNotifications';
export { default as getRecurringPayments } from 'actions/getters/getRecurringPayments';
export { default as getDirectDebit } from 'actions/getters/getDirectDebit';
export { default as getUser } from 'actions/getters/getUser';
export { default as getUserAddress } from 'actions/getters/getUserAddress';
export { default as getConsentStatements } from 'actions/getters/getConsentStatements';
export { default as getDonationHistory } from 'actions/getters/getDonationHistory';
export { default as getPaymentMethods } from 'actions/getters/getPaymentMethods';
export { default as getDonationSummary } from 'actions/getters/getDonationSummary';

// Setters
export { default as cancelRecurringPayments } from 'actions/setters/cancelRecurringPayments';
export { default as checkLogin } from 'actions/setters/checkLogin';
export { default as dispatchIsLoggedIn } from 'actions/setters/dispatchIsLoggedIn';
export { default as resetAddressSearchResults } from 'actions/setters/resetAddressSearchResults';
export { default as updateAddress } from 'actions/setters/updateAddress';
export { default as updateCountryCode } from 'actions/setters/updateCountryCode';
export { default as updateState } from 'actions/setters/updateState';
export { default as updateEmailAddress } from 'actions/setters/updateEmailAddress';
export { default as updateDateOfBirth } from 'actions/setters/updateDateOfBirth';
export { default as updatePhoneNumber } from 'actions/setters/updatePhoneNumber';
export { default as updateNameAndTitle } from 'actions/setters/updateNameAndTitle';
export { default as updateNotifications } from 'actions/setters/updateNotifications';
export { default as updateUserNotifications } from 'actions/setters/updateUserNotifications';
export { default as updatePassword } from 'actions/setters/updatePassword';
export { default as logError } from 'actions/setters/logError';
export { default as koalaAnalyticsActions } from 'actions/koalaAnalyticsActions';
export { default as updateUserConsent } from 'actions/setters/updateUserConsent';
export { default as closeConsentModal } from 'actions/setters/closeConsentModal';
export { default as deleteCard } from 'actions/setters/deleteCard';

// TODO: this is missing
export const initializeAddressForm = () => {};
