import { forms, ActionTypes as types } from '../../constants';
import { logError, submitFormToIam } from '..';
import { resetAppErrors, updatePostAppError } from './updateAppErrors';

import { auth } from '../../utils';

const log = { component: 'updateDateOfBirth' };

export default values => dispatch => {
  const userGuid = auth.getAccessToken().guid;
  const userResource = `/users/${userGuid}/dateOfBirth`;
  const { DayOfBirth, MonthOfBirth, YearOfBirth } = values;
  const dateOfBirth = `${YearOfBirth}-${MonthOfBirth}-${DayOfBirth}`;

  const body = {
    userGuid,
    dateOfBirth: dateOfBirth,
  };

  const onSuccess = () => {
    dispatch({
      type: types.UPDATE_USER,
      payload: body,
    });
    dispatch(resetAppErrors());
  };

  const onError = (error = {}) => {
    dispatch(logError(types.UPDATE_APP_STATUS_POST_ERROR, { ...log, error }));
    dispatch(updatePostAppError(error));
  };

  return dispatch(
    submitFormToIam(forms.DateOfBirth, userResource, userGuid, body, {
      clearForm: false,
      onSuccess,
      onError,
      isUpdate: true,
    }),
  );
};
