import { forms, ActionTypes as types } from '../../constants';
import { logError, submitFormToIam } from '..';
import { resetAppErrors, updatePostAppError } from './updateAppErrors';

import { auth } from '../../utils';

const log = { component: 'updatePhoneNumber' };

export default values => dispatch => {
  const userGuid = auth.getAccessToken().guid;

  const userResource = `/users/${userGuid}/phonenumber`;
  const phoneNumber = values.PhoneNumber;
  const body = {
    userGuid,
    PhoneNumber: phoneNumber,
  };

  const onSuccess = () => {
    dispatch({
      type: types.UPDATE_USER,
      payload: body,
    });
    dispatch(resetAppErrors());
  };

  const onError = (error = {}) => {
    dispatch(logError(types.UPDATE_APP_STATUS_POST_ERROR, { ...log, error }));
    dispatch(updatePostAppError(error));
  };

  return dispatch(
    submitFormToIam(forms.PhoneNumber, userResource, userGuid, body, {
      clearForm: false,
      onSuccess,
      onError,
      isUpdate: true,
    }),
  );
};
