import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import Svg from '@justgiving/svg';
import chevron from '@justgiving/icons/src/chevron-left-slim.svg';
import { paths } from 'constants';

const svg = (
  <Svg
    markup={chevron}
    className="jg-icon--slim jg-fill--primary jg-pull-left"
  />
);

const DonationHistoryLink = ({ path, label, classNames }) => (
  <Link
    to={path}
    className={`jg-block jg-clearfix jg-space-pbxs nav__settings-link ${classNames}`}
  >
    {svg}
    <div className="jg-pull-left jg-space-plsm qa-nav__settings-link">
      {label}
    </div>
  </Link>
);

DonationHistoryLink.propTypes = {
  path: PropTypes.string,
  label: PropTypes.string,
  classNames: PropTypes.string,
};

DonationHistoryLink.defaultProps = {
  path: paths.DonationHistory,
  label: 'Donation History',
  classNames: '',
};

export default DonationHistoryLink;
