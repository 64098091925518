import { Reducer } from 'redux';
import { globalAlertActions } from './GlobalAlert.actions';

type Action = {
  type: string;
  payload?: {
    message: string;
    type: 'warning' | 'error' | 'success' | 'info';
  };
};
export const globalAlert: Reducer = (state = null, action: Action) => {
  switch (action.type) {
    case globalAlertActions.set:
      return action.payload;
    case globalAlertActions.remove:
      return null;
    default:
      return state;
  }
};
