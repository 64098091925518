import React from 'react';
import { image } from '../../constants';

const httpRegex = /(http(s?)):\/\//gi;

const getProfileImage = (imageUrl: string) =>
  httpRegex.test(imageUrl)
    ? imageUrl
    : `${image.baseUrl}${imageUrl}?template=size150x150face`;

interface ProfileImageProps {
  imageUrl?: string | null;
  className?: string;
  width?: number;
  height?: number;
  rounded?: boolean;
  objectFit?: 'cover' | 'contain';
}

const ProfileImage: React.FC<ProfileImageProps> = ({
  imageUrl,
  className = '',
  width = 75,
  height = 75,
  rounded,
  objectFit = 'contain',
}) => (
  <img
    className={className}
    src={imageUrl ? getProfileImage(imageUrl) : image.defaultAvatar}
    alt=""
    width={width}
    height={height}
    style={{ objectFit, borderRadius: rounded ? '100%' : undefined }}
  />
);

export default ProfileImage;
