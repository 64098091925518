import { Cause, PaymentStatus, Payments } from '../../../types/state';
import React, { FunctionComponent, useState } from 'react';
import {
  getCurrencyLocale,
  getCurrencySymbol,
  getPaymentCardIcon,
} from '../../utils';

import { ModalPaymentData } from './RecurringCardPayments';
import Svg from '@justgiving/svg';
import classnames from 'classnames';
import humanizedDate from '../../utils/humanizedDate';
import { image as imageUrl } from '../../constants';
import { useTranslation } from 'react-i18next';

const iconChevron = require('@justgiving/icons/lib/chevron-down.svg');

type Props = {
  id: number;
  cardEnding: string;
  cause: Cause;
  paymentMethod?: string;
  predictedPayment?: Payments;
  payments: Payments[];
  status: PaymentStatus;
  openCancelModal: (paymentData: ModalPaymentData) => void;
};

const PaymentItem: FunctionComponent<Props> = ({
  cardEnding,
  cause,
  paymentMethod,
  predictedPayment,
  payments,
  status,
  openCancelModal,
  id,
}) => {
  const [morePaymentsToggle, setMorePaymentsToggle] = useState(false);

  const CauseAvatar = () => {
    const isCharity = cause.type === 'CharityDirect';
    return (
      <div
        className={`recurring-payment-item__cause-${
          isCharity ? 'charity-logo' : 'crowdfunder-img'
        }`}
      >
        <a href={cause.url}>
          <img
            src={
              cause.imageName
                ? `${imageUrl.baseUrl}${cause.imageName}?template=size150x150Crop`
                : imageUrl.defaultAvatar
            }
            alt=""
          />
        </a>
      </div>
    );
  };
  const CharityDetails = () => (
    <div className="recurring-payment-item__cause">
      <CauseAvatar />
      <div className="recurring-payment-item__cause-text">
        <h3 className="recurring-payment-item__cause-name">
          {cause.beneficiaryName}
        </h3>
        {cause.description && (
          <p className="recurring-payment-item__cause-pitch jg-text--light jg-text--small">
            {cause.description}
          </p>
        )}
      </div>
    </div>
  );
  const ShowAllBtn = () => {
    const { t } = useTranslation();

    const classes = classnames({
      'jg-icon-chevron': true,
      'jg-icon-chevron--down': morePaymentsToggle,
      'jg-icon-chevron--up': !morePaymentsToggle,
      'jg-fill--brand-secondary': true,
      'jg-pull-right': true,
      'jg-space-mlxs': true,
    });
    return (
      <button
        className="jg-btn--link"
        onClick={() => setMorePaymentsToggle(!morePaymentsToggle)}
      >
        <span>
          {!morePaymentsToggle && t('recurringCard.showPreviousPayments')}
          {morePaymentsToggle && t('recurringCard.hidePreviousPayments')}
          <Svg markup={iconChevron} className={classes} />
        </span>
      </button>
    );
  };
  type DateAndCardProps = { dateTime: string };
  const DateAndCard: FunctionComponent<DateAndCardProps> = ({ dateTime }) => {
    const { t } = useTranslation();

    return (
      <>
        <div>{dateTime}</div>
        <div className="recurring-payment-item__card-details">
          {paymentMethod && (
            <i
              className={`icon-card-${getPaymentCardIcon(
                paymentMethod,
              )}-med-color jg-space-mrxs`}
            >
              <span className="jg-sr-only">{paymentMethod}</span>
            </i>
          )}
          <span className="jg-text--light recurring-payment-item__card-ending">
            {paymentMethod ? (
              <span className="jg-sr-only">{paymentMethod}</span>
            ) : (
              <>{t('common.account')}</>
            )}{' '}
            {t('common.ending')} {cardEnding}
          </span>
        </div>
      </>
    );
  };

  type MoneyProps = {
    currency: string;
    amount: string;
    giftAid: string | null;
  };

  const Money: FunctionComponent<MoneyProps> = ({
    currency,
    amount,
    giftAid,
  }) => {
    const { t } = useTranslation();

    return (
      <div className="jg-color--primary">
        {getCurrencySymbol(currency)}
        {getCurrencyLocale(amount)}
        {giftAid && (
          <small className="recurring-payment-item__giftAid">
            + {getCurrencySymbol(currency)}
            {getCurrencyLocale(giftAid)} {t('common.giftAid')}
          </small>
        )}
      </div>
    );
  };

  const PaymentInfo = () => {
    const { t } = useTranslation();
    const latestPayment = predictedPayment || payments[0];
    const dateTime =
      latestPayment && latestPayment.dateTime
        ? humanizedDate(latestPayment.dateTime)
        : null;

    // TODO Amount label wraps wrong on just bigger than mobile
    return (
      <>
        <div className="row">
          <div className="col-xs-12 col-lg-4">
            <CharityDetails />
          </div>
          {latestPayment && (
            <div className="col-xs-4 recurring-payment-item__label">
              <h3 className="jg-h6">
                {`${
                  status !== PaymentStatus.CANCELLED ? 'Next' : 'Previous'
                } payment`}
              </h3>
            </div>
          )}
          <div className="col-xs-8 col-lg-3 recurring-payment-item__value">
            {dateTime && <DateAndCard dateTime={dateTime} />}
          </div>

          <div className="col-xs-4 recurring-payment-item__label">
            <h3 className="jg-h6">Amount</h3>
          </div>
          <div className="col-xs-8 col-md-3 recurring-payment-item__value">
            {latestPayment && (
              <Money
                amount={latestPayment && latestPayment.amount}
                currency={latestPayment && latestPayment.currency}
                giftAid={latestPayment && latestPayment.giftAid}
              />
            )}
          </div>

          <div className="col-xs-10 col-lg-2 offset-xs-1 offset-lg-0 text-center">
            {status !== PaymentStatus.CANCELLED && (
              <button
                className="jg-btn jg-btn--small jg-btn--ghost recurring-payment-item__cancel-btn"
                onClick={() =>
                  openCancelModal({
                    cause,
                    amount: latestPayment && latestPayment.amount,
                    currency: latestPayment && latestPayment.currency,
                    giftAid: latestPayment && latestPayment.giftAid,
                    id,
                  })
                }
              >
                {t('common.cancel')}
              </button>
            )}
          </div>
        </div>
        {payments.length > 0 && (
          <div className="row text-center">
            <div className="col-xs-12 recurring-payment-item__show-toggle">
              <ShowAllBtn />
            </div>
          </div>
        )}
      </>
    );
  };

  const PreviousPayments = () => {
    const previousPaymentsList = payments.map(recurringPayment => {
      const dateTime = humanizedDate(recurringPayment.dateTime);
      return (
        <li
          className="recurring-payment-item__subitem"
          key={recurringPayment.id}
        >
          <div className="row">
            <div className="col-xs-4 col-lg-3 offset-lg-4">{dateTime}</div>
            <div className="col-xs-8 col-lg-3">
              <Money
                amount={recurringPayment.amount}
                currency={recurringPayment.currency}
                giftAid={recurringPayment.giftAid}
              />
            </div>
          </div>
        </li>
      );
    });
    return <ul>{previousPaymentsList}</ul>;
  };

  return (
    <li className="recurring-payment-item">
      <PaymentInfo />
      {morePaymentsToggle && <PreviousPayments />}
    </li>
  );
};

export default PaymentItem;
