import { forms, ActionTypes as types } from '../../constants';
import { logError, submitFormToIam } from '..';
import { resetAppErrors, updatePostAppError } from './updateAppErrors';

import { auth } from '../../utils';

const log = { component: 'updateNameAndTitle' };

export default (values, _logError = logError) => dispatch => {
  const userGuid = auth.getAccessToken().guid;
  const userResource = `/users/${userGuid}/change-name`;
  const body = {
    userGuid,
    Title: values.Title,
    FirstName: values.FirstName,
    LastName: values.LastName,
  };

  const onSuccess = () => {
    dispatch({
      type: types.UPDATE_USER,
      payload: body,
    });
    dispatch(resetAppErrors());
  };

  const onError = (error = {}) => {
    dispatch(updatePostAppError(error));
    dispatch(_logError(types.UPDATE_APP_STATUS_POST_ERROR, { ...log, error }));
  };

  return dispatch(
    submitFormToIam(forms.Name, userResource, userGuid, body, {
      clearForm: false,
      onSuccess,
      onError,
    }),
  );
};
