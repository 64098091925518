import { graphqlApi } from '../api/graphqlApi';

// Hooks are generated automatically by redux toolkit query based on the
// endpoint definitions generated by graphql-code-generator's typescript-rtk-query
// plugin
// https://redux-toolkit.js.org/rtk-query/api/created-api/hooks
export const {
  useDisconnectStravaMutation,
  useThirdPartyApplicationsQuery,
  useGetViewerDonationSummariesQuery,
  useGetViewerDonationsQuery,
  useLazyGetViewerDonationsQuery,
  useGetUserPagesQuery,
  useGetUserDonationTotalsQuery,
} = graphqlApi;
