import FormError from '../components/Alert/FormError';
import Loading from '@justgiving/loading';
import { LoadingComponentProps } from 'react-loadable';
import React from 'react';

const AsyncLoading: React.FC<LoadingComponentProps> = ({
  isLoading,
  error,
  pastDelay,
}) => {
  if (isLoading) {
    return pastDelay ? <Loading /> : null;
  } else if (error) {
    return <FormError error />;
  }

  return null;
};

export default AsyncLoading;
