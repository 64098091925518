import './Card.scss';
import React, { FunctionComponent } from 'react';
import Svg from '@justgiving/svg';
import { chevronRightSlimIcon } from '@justgiving/icons/src/index.js';
import { Trans } from 'react-i18next';
import config from 'config';
import { urls } from '../../constants';

interface LinkProps {
  name?: string;
  children?: React.ReactNode;
  to: string;
  className?: string;
  isAuthSrcBB?: boolean;
}

const ChevronRightIcon = () => (
  <Svg
    markup={chevronRightSlimIcon}
    className="jg-icon--slim jg-pull-right jg-fill--empress"
  />
);

const ExternalLink = () => (
  <div className="external-link__wrapper">
    <Trans i18nKey="personalDetails.edit">Edit</Trans>
    <span className="external-link__icon">
      <svg fill="none" viewBox="0 0 12 12" width={12} height={12}>
        <path
          fill="#818B99"
          d="M7.333 0v1.333h2.394L3.173 7.887l.94.94 6.554-6.554v2.394H12V0m-1.333 10.667H1.333V1.333H6V0H1.333C.593 0 0 .6 0 1.333v9.334A1.333 1.333 0 0 0 1.333 12h9.334A1.334 1.334 0 0 0 12 10.667V6h-1.333v4.667Z"
        />
      </svg>
    </span>
  </div>
);

export const Card: FunctionComponent<LinkProps> = ({
  children,
  to,
  isAuthSrcBB = false,
}) => {
  const internalUrl = `${config.baseUrl}${config.basename}${to}`;

  const isEnabledBBID = config.features.isEnabledBBID;

  return (
    <div className={'wrapper jg-display-flex jg-flex-aic jg-flex-jcb '}>
      {children}
      <a
        href={
          isEnabledBBID && isAuthSrcBB ? urls.BlackBaudUserProfile : internalUrl
        }
        target={isEnabledBBID && isAuthSrcBB ? '_blank' : undefined}
        rel="noopener noreferrer"
        className="chevronButton jg-color--empress"
      >
        {isEnabledBBID && isAuthSrcBB ? <ExternalLink /> : <ChevronRightIcon />}
      </a>
    </div>
  );
};
