import { getFromApi, logError } from '..';
import { recurringPayments, ActionTypes as types } from '../../constants';

import { auth } from '../../utils';
import { updateFetchAppError } from '../setters/updateAppErrors';

const log = { component: 'getDirectDebit' };

export default (_logError = logError) => (dispatch, getState) => {
  const { isLoaded } = getState().app.directDebits.status;

  if (isLoaded) return Promise.resolve('isLoaded');

  dispatch({
    type: types.FETCH_DIRECTDEBIT_PAYMENTS,
  });

  const directDebitResource = `${
    recurringPayments.baseUrl
  }/api/v1/direct-debits/user/${auth.getAccessToken().guid}`;

  return dispatch(getFromApi(directDebitResource))
    .then(([res, json]) => {
      if (res.ok) {
        dispatch({
          type: types.FETCH_DIRECTDEBIT_PAYMENTS_SUCCESS,
          payload: json,
        });
      } else if (json && json.errorSummary) {
        const error = json.errorSummary;
        dispatch(updateFetchAppError());
        dispatch({ type: types.FETCH_DIRECTDEBIT_PAYMENTS_FAILURE });
        dispatch(
          _logError(types.UPDATE_APP_STATUS_FETCH_ERROR, { ...log, error }),
        );
      } else {
        dispatch(updateFetchAppError());
        dispatch({ type: types.FETCH_DIRECTDEBIT_PAYMENTS_FAILURE });
        dispatch(
          _logError(types.UPDATE_APP_STATUS_FETCH_ERROR, { ...log, res }),
        );
      }
    })
    .catch(error => {
      dispatch(updateFetchAppError());
      dispatch({ type: types.FETCH_DIRECTDEBIT_PAYMENTS_FAILURE });
      dispatch(
        _logError(types.UPDATE_APP_STATUS_FETCH_ERROR, { ...log, error }),
      );
    });
};
