import AsyncPageLoading from './AsyncPageLoading';
import Loadable from 'react-loadable';

export const AsyncPageName = Loadable({
  loader: () => import(/* webpackChunkName: 'name' */ './Name'),
  loading: AsyncPageLoading,
});

export const AsyncPageEmail = Loadable({
  loader: () => import(/* webpackChunkName: 'email' */ './Email'),
  loading: AsyncPageLoading,
});

export const AsyncPageAddress = Loadable({
  loader: () => import(/* webpackChunkName: 'address' */ './Address'),
  loading: AsyncPageLoading,
});

export const AsyncPageDateOfBirth = Loadable({
  loader: () => import(/* webpackChunkName: 'date-of-birth' */ './DateOfBirth'),
  loading: AsyncPageLoading,
});

export const AsyncPagePhoneNumber = Loadable({
  loader: () => import(/* webpackChunkName: 'phone-number' */ './PhoneNumber'),
  loading: AsyncPageLoading,
});

export const AsyncPageAddressSearchResults = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'address-search-results' */ './AddressSearchResultsPage'
    ),
  loading: AsyncPageLoading,
});

export const AsyncPagePassword = Loadable({
  loader: () => import(/* webpackChunkName: 'password' */ './Password'),
  loading: AsyncPageLoading,
});

export const AsyncPageDonationHistory = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'donation-history' */ './DonationHistory'),
  loading: AsyncPageLoading,
});

export const AsyncPagePaymentMethods = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'notifications' */ './PaymentMethods'),
  loading: AsyncPageLoading,
});

export const AsyncPageNotifications = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'notifications' */ './Notifications'),
  loading: AsyncPageLoading,
});

export const AsyncPageNotFound = Loadable({
  loader: () => import(/* webpackChunkName: 'not-found' */ './NotFound'),
  loading: AsyncPageLoading,
});

export const AsyncPageFundraising = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'fundraising' */ './Fundraising/Fundraising'),
  loading: AsyncPageLoading,
});

export const AsyncPageProfile = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'fundraising' */ './Profile/Profile'),
  loading: AsyncPageLoading,
});

export default {
  AsyncPageName,
  AsyncPageEmail,
  AsyncPageAddress,
  AsyncPageDateOfBirth,
  AsyncPagePhoneNumber,
  AsyncPageAddressSearchResults,
  AsyncPagePassword,
  AsyncPageDonationHistory,
  AsyncPagePaymentMethods,
  AsyncPageNotifications,
  AsyncPageNotFound,
  AsyncPageFundraising,
  AsyncPageProfile,
};
