import { forms, ActionTypes as types } from 'constants';
import { getFromWalletServiceApi, logError } from 'actions';

import { auth } from '../../utils';
import { initialize } from 'redux-form';
import { updateFetchAppError } from 'actions/setters/updateAppErrors';

const log = { component: 'getPaymentMethods' };

// eslint-disable-next-line max-len
export default (
  _getFromWalletServiceApi = getFromWalletServiceApi,
  _logError = logError,
) => (dispatch, getState) => {
  const { isLoaded } = getState().app.paymentMethods;

  if (isLoaded) return Promise.resolve('isLoaded');

  dispatch({
    type: types.FETCH_PAYMENT_METHODS,
  });

  const paymentMethodsResource = `v1/donors/${
    auth.getAccessToken().guid
  }/wallet/paymentcards`;

  return dispatch(_getFromWalletServiceApi(paymentMethodsResource))
    .then(([res, json]) => {
      if (res.ok) {
        dispatch({
          type: types.FETCH_PAYMENT_METHODS_SUCCESS,
          payload: json,
        });
        dispatch(initialize(forms.Notifications, json));
      } else if (json && json.Errors && json.Errors.length > 0) {
        const error = json.Errors[0].ErrorMessage;
        dispatch(updateFetchAppError());
        dispatch({ type: types.FETCH_PAYMENT_METHODS_FAILURE });
        dispatch(
          _logError(types.UPDATE_APP_STATUS_FETCH_ERROR, { ...log, error }),
        );
      } else {
        dispatch(updateFetchAppError());
        dispatch({ type: types.FETCH_PAYMENT_METHODS_FAILURE });
        dispatch(
          _logError(types.UPDATE_APP_STATUS_FETCH_ERROR, { ...log, res }),
        );
      }
    })
    .catch(error => {
      dispatch(updateFetchAppError());
      dispatch({ type: types.FETCH_PAYMENT_METHODS_FAILURE });
      dispatch(
        _logError(types.UPDATE_APP_STATUS_FETCH_ERROR, { ...log, error }),
      );
    });
};
