import { auth, getBearerHeaders } from '../../utils';
import {
  reset,
  setSubmitFailed,
  setSubmitSucceeded,
  startSubmit,
  stopSubmit,
} from 'redux-form';

import { checkLogin } from 'actions';

export const sendToApi = (
  body,
  url,
  method = 'POST',
  isPreferencesService,
  _fetch = fetch,
) => {
  body.RefreshToken = auth.getRefreshToken();
  const headers = getBearerHeaders();
  const options = {
    method,
    headers,
    body: JSON.stringify(body),
  };
  if (!isPreferencesService) {
    options.credentials = 'include';
  }

  const fetchPromise = () =>
    _fetch(url, options).then(res => Promise.all([res, res.json()]));

  return fetchPromise();
};

// eslint-disable-next-line  max-len
export default (
  formName,
  url,
  userGuid,
  body,
  callerOptions = {},
  _checkLogin = checkLogin,
  _sendToApi = sendToApi,
) => dispatch => {
  const defaultOptions = {
    clearForm: true,
    onSuccess: undefined,
    onFetching: undefined,
    onError: undefined,
    isUpdate: false,
    ignoreFormActions: false,
  };
  const options = Object.assign({}, defaultOptions, callerOptions);

  if (!options.ignoreFormActions) dispatch(startSubmit(formName));

  if (options.onFetching) options.onFetching();

  return dispatch(_checkLogin())
    .then(() =>
      _sendToApi(
        body,
        url,
        callerOptions.isUpdate ? 'PUT' : 'POST',
        callerOptions.isPreferencesService,
      ),
    )
    .then(([res, json]) => {
      if (res.ok) {
        if (!options.ignoreFormActions) {
          dispatch(stopSubmit(formName));
        }
        if (!options.ignoreFormActions && options.clearForm) {
          dispatch(reset(formName));
        }
        if (!options.ignoreFormActions) {
          dispatch(setSubmitSucceeded(formName));
        }
        if (options.onSuccess) {
          options.onSuccess(json);
        }
      } else if (json && json.Errors && json.Errors.length > 0) {
        const errorMessage = json.Errors[0].ErrorMessage;
        if (options.onError) {
          options.onError(errorMessage);
        }
      } else if (options.onError) {
        options.onError(res.json());
      }
    })
    .catch(exception => {
      if (!options.ignoreFormActions) dispatch(setSubmitFailed(formName));
      if (!options.ignoreFormActions)
        dispatch(stopSubmit(formName, { _error: exception.message }));
    });
};
