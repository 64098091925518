import { auth, logError } from '../../utils';
import { forms, ActionTypes as types } from '../../constants';
import { resetAppErrors, updatePostAppError } from './updateAppErrors';

import { submitFormToIam } from '..';

const log = { component: 'updateAddress' };

export default (values, _logError = logError) => (dispatch, getState) => {
  const userGuid = auth.getAccessToken().guid;
  const userAddressId = getState().userAddress
    ? getState().userAddress.userAddressId
    : undefined;
  const userAddressResource = userAddressId
    ? `/users/${userGuid}/address/${userAddressId}`
    : `/users/${userGuid}/address`;

  const body = {
    AddressLine1: values.AddressLine1,
    AddressLine2: values.AddressLine2,
    Town: values.Town,
    State: values.State,
    County: values.County,
    CountryCode: values.CountryCode,
    PostCode: values.PostCode,
  };

  const onSuccess = json => {
    const bodyWithCountry = { ...body, Country: json.Result.Country };
    dispatch({
      type: types.UPDATE_USER,
      payload: bodyWithCountry,
    });
    dispatch({
      type: types.UPDATE_USER_ADDRESS,
      payload: bodyWithCountry,
    });
    dispatch(resetAppErrors());
  };

  const onError = (error = {}) => {
    dispatch(updatePostAppError(error));
    dispatch(_logError(types.UPDATE_APP_STATUS_POST_ERROR, { ...log, error }));
  };

  return dispatch(
    submitFormToIam(forms.Address, userAddressResource, userGuid, body, {
      clearForm: false,
      onSuccess,
      onError,
      isUpdate: userAddressId !== undefined,
    }),
  );
};
