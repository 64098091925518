import DonationHistoryLink from './DonationHistoryLink';
import { NavLink } from './NavLink';
import { NavLinks } from './NavLinks';
import PersonalDetailsLink from './PersonalDetailsLink';
import SettingsLink from './SettingsLink';

export { NavLink } from './NavLink';
export { NavLinks } from './NavLinks';
export { default as SettingsLink } from './SettingsLink';
export { default as PersonalDetailsLink } from './PersonalDetailsLink';
export { default as DonationHistoryLink } from './DonationHistoryLink';

export default {
  NavLink,
  NavLinks,
  SettingsLink,
  PersonalDetailsLink,
  DonationHistoryLink,
};
