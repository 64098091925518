import {
  AsyncPageAddress,
  AsyncPageAddressSearchResults,
  AsyncPageDonationHistory,
  AsyncPageEmail,
  AsyncPageDateOfBirth,
  AsyncPageFundraising,
  AsyncPageName,
  AsyncPageNotifications,
  AsyncPagePassword,
  AsyncPagePaymentMethods,
  AsyncPagePhoneNumber,
} from './pages/AsyncPage';
import React, { ReactNode } from 'react';
import { features, paths } from './constants';

import AppSettings from './pages/AppSettings';
import DonationSummary from './pages/DonationSummary';
import MonthlyDonations from './pages/MonthlyDonations';
import Personal from './pages/Personal';
import Security from './pages/Security';
import { TFunction } from 'i18next';
import config from './config';

export interface Route {
  path: string; // React-router route path
  component: ReactNode; // Component displayed when route is matched
  name: string | null; // If name is provided, the route will be added to the side navigation
  isFeatureDisabled: boolean; // Hide the route from the side navigation
  isPrivate: boolean; // Auth will be checked if route is private
  isExact: boolean; // React router will match the route exactly
  description?: string;
}

export function getRoutes(t: TFunction): Route[] {
  return [
    {
      path: paths.Fundraising,
      component: <AsyncPageFundraising />,
      isExact: false,
      isFeatureDisabled: !config.features.showFundraisingSection,
      isPrivate: true,
      name: 'Fundraising',
      description: t('fundraising.navDescription'),
    },
    {
      path: paths.Personal,
      component: <Personal />,
      name: 'Personal details',
      isFeatureDisabled: false,
      isPrivate: true,
      isExact: true,
      description: t('personalDetails.navDescription'),
    },
    {
      path: paths.Name,
      component: <AsyncPageName />,
      name: null,
      isFeatureDisabled: false,
      isPrivate: true,
      isExact: false,
    },
    {
      path: paths.Email,
      component: <AsyncPageEmail />,
      name: null,
      isFeatureDisabled: false,
      isPrivate: true,
      isExact: false,
    },
    {
      path: paths.DateOfBirth,
      component: <AsyncPageDateOfBirth />,
      name: null,
      isFeatureDisabled: false,
      isPrivate: true,
      isExact: false,
    },
    {
      path: paths.PhoneNumber,
      component: <AsyncPagePhoneNumber />,
      name: null,
      isFeatureDisabled: false,
      isPrivate: true,
      isExact: false,
    },
    {
      path: paths.Security,
      component: <Security />,
      name: 'Security',
      isFeatureDisabled: false,
      isPrivate: true,
      isExact: true,
      description: t('security.navDescription'),
    },
    {
      path: paths.Password,
      component: <AsyncPagePassword />,
      name: null,
      isFeatureDisabled: false,
      isPrivate: true,
      isExact: false,
    },
    {
      path: paths.DonationHistory,
      component: <AsyncPageDonationHistory />,
      name: 'Donation history',
      isFeatureDisabled: !features.donationHistory,
      isPrivate: true,
      isExact: false,
      description: t('donationHistory.navDescription'),
    },
    {
      path: paths.DonationSummary,
      component: <DonationSummary />,
      name: null,
      isFeatureDisabled: false,
      isPrivate: true,
      isExact: false,
    },
    {
      path: paths.MonthlyDonations,
      component: <MonthlyDonations />,
      name: 'Monthly donations',
      isFeatureDisabled: !features.recurringDonations,
      isPrivate: true,
      isExact: false,
      description: t('MonthlyDonations.navDescription'),
    },
    {
      path: paths.PaymentMethods,
      component: <AsyncPagePaymentMethods />,
      name: 'Payment methods',
      isFeatureDisabled: false,
      isPrivate: true,
      isExact: false,
      description: t('paymentMethods.navDescription'),
    },
    {
      path: paths.Notifications,
      component: <AsyncPageNotifications />,
      name: 'Preferences',
      isFeatureDisabled: false,
      isPrivate: true,
      isExact: false,
      description: t('notificationsForm.navDescription'),
    },
    {
      path: paths.Address,
      component: <AsyncPageAddress />,
      name: null,
      isFeatureDisabled: false,
      isPrivate: true,
      isExact: false,
    },
    {
      path: paths.AddressResults,
      component: <AsyncPageAddressSearchResults />,
      name: null,
      isFeatureDisabled: false,
      isPrivate: true,
      isExact: false,
    },
    {
      path: paths.Apps,
      component: <AppSettings />,
      name: 'Apps',
      isFeatureDisabled: false,
      isPrivate: true,
      isExact: false,
      description: t('Apps.navDescription'),
    },
  ];
}
