import { ActionTypes as types } from '../constants';

const initialState = {
  isLoggedIn: false,
};

// TODO: change this to accessToken or auth
const loginStatus = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.UPDATE_LOGIN_STATUS:
      return {
        ...state,
        isLoggedIn: action.payload.isLoggedIn,
      };
    default:
      return state;
  }
};

export default loginStatus;
