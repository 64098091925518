import DirectDebitModal, { ModalProps } from './DirectDebitModal';
import { PaymentStatus, State } from '../../../types/state';
import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '@justgiving/loading';
import RecurringPaymentHeader from './RecurringPaymentHeader';
import RecurringPaymentItem from './RecurringPaymentsItem';
import { getDirectDebit } from '../../actions';
import { useTranslation } from 'react-i18next';

type DispatchStateAction = {
  type: 'open_cancel_modal' | 'close_cancel_modal';
  mandateData?: ModalMandateData;
};
export type ModalMandateData = Omit<ModalProps, 'close'>;
type ComponentState = {
  isCancelModalOpen: boolean;
  mandateData?: ModalMandateData;
};

const DirectDebit: FunctionComponent = () => {
  const { t } = useTranslation();
  const mandates = useSelector(
    (state: State) => state.app.directDebits.data.mandates,
  );

  const [{ isCancelModalOpen, mandateData }, dispatchState] = useReducer(
    (state: ComponentState, action: DispatchStateAction) => {
      switch (action.type) {
        case 'open_cancel_modal':
          return {
            isCancelModalOpen: true,
            mandateData: action.mandateData,
          };
        case 'close_cancel_modal':
          return {
            isCancelModalOpen: false,
          };
        default:
          return state;
      }
    },
    { isCancelModalOpen: false },
  );

  const openCancelModal = (mandateData: ModalMandateData) =>
    dispatchState({ type: 'open_cancel_modal', mandateData });

  const activeDirectDebits = useMemo(
    () =>
      mandates.filter(payment => payment.status !== PaymentStatus.CANCELLED),
    [mandates],
  );

  const activePayments = useMemo(
    () =>
      activeDirectDebits.map(paymentData => (
        <RecurringPaymentItem
          key={paymentData.mandateId}
          openCancelModal={openCancelModal}
          cardEnding={paymentData.accountEnding}
          predictedPayment={paymentData.predictedNextPayment}
          cause={paymentData.cause}
          payments={paymentData.payments}
          status={paymentData.status}
          id={paymentData.mandateId}
        />
      )),
    [activeDirectDebits],
  );

  const isLoaded = useSelector(
    (state: State) => state.app.directDebits.status.isLoaded,
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDirectDebit());
  }, [dispatch]);

  if (!isLoaded) return <Loading />;

  if (activeDirectDebits.length === 0) {
    return <p className="jg-text--medium-light">{t('directDebit.noActive')}</p>;
  }

  return (
    <>
      <RecurringPaymentHeader />
      <ul className="monthly-donations__list">{activePayments}</ul>
      {isCancelModalOpen && mandateData && (
        <DirectDebitModal
          close={() => dispatchState({ type: 'close_cancel_modal' })}
          {...mandateData}
        />
      )}
    </>
  );
};

export default DirectDebit;
