import LngDetector from 'i18next-browser-languagedetector';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { loadTranslations } from '@justgiving/page-components';
import resourcesToBackend from 'i18next-resources-to-backend';

i18next
  .use(initReactI18next)
  .use(
    resourcesToBackend(
      async (lang: string, namespace: string) =>
        (await loadTranslations(lang, namespace)) ??
        import(
          /* webpackChunkName: "lang/[request]" */ `../lang/${lang}.json5`
        ),
    ),
  )
  .use(LngDetector)
  .init({
    whitelist: ['en', 'en-GB', 'en-US', 'en-AU', 'en-CA'],
    fallbackLng: 'en',
    keySeparator: false,

    interpolation: {
      escapeValue: false,
    },

    detection: {
      order: ['querystring', 'navigator'],
      lookupQuerystring: 'locale',
    },
  });

export default i18next;
