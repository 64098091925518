import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { getDonationSummary, koalaAnalyticsActions } from 'actions';
import DonationSummaryForm from 'components/DonationSummaryForm/DonationSummaryFormComponent';
import { features, forms } from 'constants';
import { resetAppErrors } from 'actions/setters/updateAppErrors';

const fetchData = (dispatch, id) => dispatch(getDonationSummary(id));

const DonationSummaryFormContainer = reduxForm({
  form: forms.DonationSummary,
})(DonationSummaryForm);

const mapStateToProps = state => ({
  status: {
    isFetching: state.app.donationSummary.isFetching,
    ...state.app.appErrors,
  },
  donation: state.app.donationSummary.donation,
});

export const mapDispatchToProps = dispatch => ({
  fetchData: id => {
    fetchData(dispatch, id);
  },
  trackPageView: (subtype, pageSection) => {
    koalaAnalyticsActions.trackPageView(subtype, pageSection)(dispatch);
  },
  trackLinkClick: (subtype, pageSection, eventValue) => {
    koalaAnalyticsActions.trackLinkClick(
      subtype,
      pageSection,
      eventValue,
    )(dispatch);
  },
  resetAppErrors: () => {
    dispatch(resetAppErrors());
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) =>
  Object.assign({}, stateProps, dispatchProps, ownProps, { features });

const DonationSummaryFormContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(DonationSummaryFormContainer);

DonationSummaryFormContainerConnected.fetchData = fetchData;

export default DonationSummaryFormContainerConnected;
