import JwtDecode from 'jwt-decode';
import auth from './auth';
import { cookieNames } from '../constants';

export const getDecodedToken = () => {
  const authCookie = `${cookieNames.authCookieName}=${auth.getAuthCookie()}`;
  const accessToken = authCookie.substring(authCookie.indexOf('=') + 14);
  const decodedJwt = accessToken && JwtDecode(accessToken);

  return decodedJwt;
};
