import React from 'react';
import SettingsLink from './SettingsLink';
import { paths } from '../../constants';

const PersonalDetailsLink = () => (
  <SettingsLink
    path={paths.Personal}
    label="Personal details"
    className="nav__personal-link"
  />
);

export default PersonalDetailsLink;
