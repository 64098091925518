import appReducer from './reducers';
import { auth } from './utils';
import { reducer as formReducer } from 'redux-form';
import { globalAlert } from './components/GlobalAlert/GlobalAlert.reducer';
import { graphqlApi } from './api/graphqlApi';
import { profileServiceApi } from './api/profileServiceApi';
import { configureStore as rtkConfigureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

export const configureStore = (state?: any) =>
  rtkConfigureStore({
    preloadedState: state,
    reducer: {
      app: appReducer,
      form: formReducer,
      globalAlert,
      [graphqlApi.reducerPath]: graphqlApi.reducer,
      [profileServiceApi.reducerPath]: profileServiceApi.reducer,
    },
    middleware: [
      thunk.withExtraArgument(auth),
      graphqlApi.middleware,
      profileServiceApi.middleware,
    ],
  });

export const store = configureStore();
