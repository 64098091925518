import { ActionTypes as types } from 'constants';

export default (i, consent) => dispatch => {
  dispatch({
    type: types.UPDATE_USER_CONSENT,
    payload: {
      index: i,
      consent,
    },
  });
};
