import React, { FunctionComponent } from 'react';

import { NavLink } from './NavLink';
import { RequiredProperty } from '../../utils/typeUtils';
import { Route } from '../../routes';
import config from '../../config';
import { paths } from '../../constants';
import { useTranslation } from 'react-i18next';

export const NavLinks: FunctionComponent<NavLinksProps> = ({ routes }) => {
  const { t } = useTranslation();

  return (
    <ul className="jg-space-mn">
      {config.features.showProfilePage && (
        <li key={paths.Index} className="navigation__li">
          <NavLink path={paths.Index} name={t('common.profile')} exact />
        </li>
      )}

      {routes
        .filter(hasName)
        .filter(({ isFeatureDisabled }) => !isFeatureDisabled)
        .map(({ path, name }) => (
          <li key={path} className="navigation__li">
            <NavLink path={path} name={name} />
          </li>
        ))}
    </ul>
  );
};

function hasName(route: Route): route is RequiredProperty<Route> {
  return route.name !== null && route.name !== undefined;
}

interface NavLinksProps {
  routes: Route[];
}
