/* eslint-disable jsx-a11y/no-static-element-interactions, react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import { paths } from 'constants';
import { track } from 'utils/koala-analytics';
import { Trans } from 'react-i18next';

const propTypes = {
  phoneNumber: PropTypes.string,
};

const defaultProps = {
  phoneNumber: undefined,
};

const trackEditPhoneNumber = () => {
  const trackingData = {
    event: 'link click',
    subtype: 'hyperlink',
    page_section: 'personal details > phone number',
    event_value: 'edit',
  };
  track(trackingData);
};

const PhoneNumber = props => (
  <section className="details-block phoneNumber">
    <div className="jg-space-phsm@sm jg-space-phn@lg">
      <div className="jg-text--medium-light">
        <Trans i18nKey="personalDetails.phone">Phone number</Trans>
        <br />
      </div>
      <p className="jg-text--dark">
        <span className="data-value">{props.phoneNumber}</span>
        <Link
          to={paths.PhoneNumber}
          onClick={trackEditPhoneNumber}
          className="jg-edit-link qa-link__phoneNumber jg-space-mlsm"
        >
          <Trans i18nKey="personalDetails.edit">Edit</Trans>
        </Link>
      </p>
    </div>
  </section>
);

PhoneNumber.propTypes = propTypes;

PhoneNumber.defaultProps = defaultProps;

export default PhoneNumber;
