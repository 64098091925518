import React, { ReactNode, useEffect } from 'react';
import { useAppSelector, useThunkDispatch } from '../../hooks/redux';

import { Route } from 'react-router-dom';
import checkLogin from '../../actions/setters/checkLogin';
import useRedirectToLogin from '../../hooks/useRedirectToLogin';
import useRedirectToCharityHome from '../../hooks/useRedirectToCharityHome';
import auth from 'utils/auth';

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  children,
  exact,
  path,
}) => {
  const dispatch = useThunkDispatch();
  const redirectToLogin = useRedirectToLogin();
  const redirectToCharityHome = useRedirectToCharityHome();
  const isLoggedIn = useAppSelector(state => state.app.loginStatus.isLoggedIn);
  const isOnlyCharity = auth.isCharity() && !auth.isConsumer();

  useEffect(() => {
    dispatch(checkLogin()).catch(() => {
      redirectToLogin();
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    dispatch(() => {
      if (isLoggedIn && isOnlyCharity) redirectToCharityHome();
    });
  }, []); //eslint-disable-line

  return (
    <Route
      exact={exact}
      path={path}
      render={() => (isLoggedIn ? children : null)}
    />
  );
};

export interface PrivateRouteProps {
  children: ReactNode;
  path?: string;
  exact?: boolean;
}
