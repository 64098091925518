import i18next from 'i18next';

export function enumerate(items: string[]) {
  const lastItem = items[items.length - 1];
  const rest = items.slice(0, items.length - 1);

  if (rest.length) {
    return `${rest.join(', ')} ${i18next.t('common.and')} ${lastItem}`;
  }

  return lastItem;
}
