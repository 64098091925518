import { ActionTypes as types } from '../constants';

export const trackPageView = (subtype, pageSection, eventValue) => dispatch => {
  if (eventValue === undefined) {
    dispatch({
      type: types.KOALA_TRACK_PAGE_VIEW,
      payload: {
        meta: {
          analytics: {
            event: 'page view',
            subtype,
            page_section: pageSection,
          },
        },
      },
    });
  } else {
    dispatch({
      type: types.KOALA_TRACK_PAGE_VIEW,
      payload: {
        meta: {
          analytics: {
            event: 'page view',
            subtype,
            page_section: pageSection,
            event_value: eventValue,
          },
        },
      },
    });
  }
};

export const trackLinkClick = (
  subtype,
  pageSection,
  eventValue,
) => dispatch => {
  dispatch({
    type: types.KOALA_TRACK_LINK_CLICK,
    payload: {
      meta: {
        analytics: {
          event: 'link click',
          subtype,
          page_section: pageSection,
          event_value: eventValue,
        },
      },
    },
  });
};

export const trackEventExtended = payload => dispatch => {
  dispatch({
    type: types.KOALA_TRACK_EVENT_EXTENDED,
    payload: {
      meta: {
        analytics: payload,
      },
    },
  });
};

export default {
  trackPageView,
  trackLinkClick,
  trackEventExtended,
};
