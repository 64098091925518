import { dispatchIsLoggedIn } from '../../actions';

export default () => async (dispatch, _getState, auth) => {
  await auth.refreshAccessTokenIfExpired().catch(() => {
    dispatch(dispatchIsLoggedIn(false));
    return Promise.reject();
  });

  dispatch(dispatchIsLoggedIn());
  return Promise.resolve();
};
