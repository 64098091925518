export default (unixTime: string) => {
  // TODO move next line to en-content.js
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const date = new Date(unixTime);
  return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
};
