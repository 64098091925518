// TODO: move to toolkit. Copied from https://source.justgiving.com/JustGiving/GG.FrontEnd.App.DonationCollection/blob/master/app/utils/browser.js
// maps API card names to CSS card icon names
export default cardType => {
  switch (cardType) {
    case 'VisaCredit':
      return 'visa';
    case 'VisaDebit':
      return 'delta';
    case 'MCICredit':
      return 'mastercard';
    case 'MCIDebit':
      return 'mastercard';
    case 'VisaElectron':
      return 'visa-electron';
    case 'AmericanExpress':
      return 'american-express';
    case 'Maestro':
      return 'mastercard';
    default:
      return '';
  }
};
