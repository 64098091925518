import './Personal.scss';
import React, { useState } from 'react';

import Modal from '@justgiving/modal';
import ProfileImage from '../../components/ProfileImage/ProfileImage';
import UploadProfileImage from '../../components/UploadProfileImage/UploadProfileImage';
import { paths } from '../../constants';
import { track } from '../../utils/koala-analytics';
import { useTranslation } from 'react-i18next';
import { getDecodedToken } from 'utils/getDecodeToken';
import config from 'config';

interface NameProps {
  title: string;
  first: string;
  last: string;
  profilePictureUrl: string;
}

const trackEditPhoto = () => {
  const trackingData = {
    event: 'link click',
    subtype: 'hyperlink',
    page_section: 'personal details > photo',
    event_value: 'edit',
  };
  track(trackingData);
};

const trackEditName = () => {
  const trackingData = {
    event: 'link click',
    subtype: 'hyperlink',
    page_section: 'personal details > name',
    event_value: 'edit',
  };
  track(trackingData);
};

const Name: React.FC<NameProps> = ({
  title,
  first,
  last,
  profilePictureUrl,
}) => {
  const { t } = useTranslation();
  const [showImageUploadModal, setShowImageUploadModal] = useState(false);

  const decodedToken: any = getDecodedToken();
  const isAuthSrcBB = decodedToken && decodedToken.auth_src === 'BB';

  return (
    <section className="details-block name">
      <div className="jg-space-phsm@sm jg-space-phn@lg">
        <div className="name__avatar jg-icon--profile">
          <ProfileImage
            imageUrl={profilePictureUrl}
            rounded
            objectFit="cover"
          />
          <br />
          <button
            onClick={() => {
              trackEditPhoto();
              setShowImageUploadModal(true);
            }}
            className="jg-text--link qa-link__profile-image"
          >
            {t('personalDetails.edit')}
          </button>
          <Modal
            isOpen={showImageUploadModal}
            onRequestClose={() => setShowImageUploadModal(false)}
            preventScroll={true}
          >
            <UploadProfileImage
              onImageUploaded={() => setShowImageUploadModal(false)}
              onImageRemoved={() => setShowImageUploadModal(false)}
              currentImageUrl={profilePictureUrl}
            />
          </Modal>
        </div>
        <div className="name__details jg-space-mtsm">
          <p className="jg-text--medium-regular">{t('personalDetails.name')}</p>
          <p>
            <span className="data-value">
              {title === 'Other' ? '' : title} {first} {last}
            </span>
            <a
              href={
                config.features.isEnabledBBID && isAuthSrcBB
                  ? 'https://signin.blackbaud.com/userprofile/'
                  : `${config.baseUrl}${config.basename}${paths.Name}`
              }
              onClick={trackEditName}
              className="jg-edit-link qa-link__name-and-title jg-space-mlsm"
              target={
                config.features.isEnabledBBID && isAuthSrcBB
                  ? '_blank'
                  : undefined
              }
              rel="noreferrer"
            >
              {t('personalDetails.edit')}
              {config.features.isEnabledBBID && isAuthSrcBB && (
                <span className="external-link__icon">
                  <svg fill="none" viewBox="0 0 12 12" width={12} height={12}>
                    <path
                      fill="#818B99"
                      d="M7.333 0v1.333h2.394L3.173 7.887l.94.94 6.554-6.554v2.394H12V0m-1.333 10.667H1.333V1.333H6V0H1.333C.593 0 0 .6 0 1.333v9.334A1.333 1.333 0 0 0 1.333 12h9.334A1.334 1.334 0 0 0 12 10.667V6h-1.333v4.667Z"
                    />
                  </svg>
                </span>
              )}
            </a>
          </p>
        </div>
      </div>
    </section>
  );
};

export default Name;
