import React, { Component } from 'react';
import { donationTypes, image } from 'constants';

import FormError from 'components/Alert/FormError';
import Loading from '@justgiving/loading';
import ProfileImageComponent from '../ProfileImage/ProfileImage';
import { PropTypes } from 'prop-types';
import { Trans } from 'react-i18next';
import { getCurrencySymbol } from 'utils';
import moment from 'moment';

export class DonationSummaryForm extends Component {
  static propTypes = {
    fetchData: PropTypes.func.isRequired,
    trackPageView: PropTypes.func.isRequired,
    donation: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    status: PropTypes.shape({
      isFetching: PropTypes.bool.isRequired,
      fetchError: PropTypes.bool.isRequired,
      postError: PropTypes.bool.isRequired,
      errorMessage: PropTypes.string,
    }).isRequired,
    resetAppErrors: PropTypes.func.isRequired,
    donationId: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  };

  static defaultProps = {
    donation: {},
  };

  componentDidMount() {
    const { fetchData, trackPageView, donationId } = this.props;

    fetchData(donationId);
    trackPageView('donationSummary', 'donationSummary');
  }

  componentWillUnmount() {
    this.props.resetAppErrors();
  }

  // eslint-disable-next-line
  onPageImageError(event) {
    event.target.src = image.defaultImage;
  }

  // eslint-disable-next-line
  onBeneficiaryImageError(event) {
    event.target.src = image.defaultImage;
  }

  render() {
    const {
      status,
      donation,
      error, // redux-form error
    } = this.props;

    const loader = (
      <div className="jg-space-mtlg">
        <Loading size="large" colour="purple" />
      </div>
    );

    if (status.isFetching) {
      return loader;
    }

    return (
      <div className="donation-summary">
        {status.errorMessage || error ? (
          <FormError error />
        ) : (
          <div>
            {(() => {
              switch (donation.donationType) {
                case donationTypes.charityFRP:
                  return (
                    <div className="donation-summary-images-wrapper">
                      <img
                        alt=""
                        className="jg-br--circle"
                        src={
                          donation.pageImageUri
                            ? donation.pageImageUri
                            : image.defaultAvatar
                        }
                        onError={this.onPageImageError}
                      />
                      <img
                        alt=""
                        className=""
                        src={
                          donation.beneficiaryImageUri
                            ? donation.beneficiaryImageUri
                            : image.defaultImage
                        }
                        onError={this.onBeneficiaryImageError}
                      />
                    </div>
                  );
                case donationTypes.crowdfunding:
                case donationTypes.globalGivingProject:
                case donationTypes.charityDirect:
                case donationTypes.campaignDirect:
                  return (
                    <div className="donation-summary-images-wrapper">
                      <img
                        alt=""
                        src={
                          donation.beneficiaryImageUri
                            ? donation.beneficiaryImageUri
                            : image.defaultImage
                        }
                        onError={this.onBeneficiaryImageError}
                      />
                    </div>
                  );
                default:
                  return '';
              }
            })()}

            <div className="donation-summary-details-wrapper">
              <h2>
                <Trans i18nKey="donationSummaryForm.details">Details</Trans>
              </h2>

              <div className="row">
                <div className="col-xs-3">
                  <Trans i18nKey="donationSummaryForm.date">Date</Trans>
                </div>
                <div className="col-xs-9">
                  {moment(donation.donationDate).format('DD MMM, YYYY')}
                </div>
              </div>

              {(() => {
                switch (donation.donationType) {
                  case donationTypes.charityFRP:
                    return (
                      <div>
                        <div className="row">
                          <div className="col-xs-3">
                            <Trans i18nKey="donationSummaryForm.fundraisingPage">
                              Fundraising page
                            </Trans>
                          </div>
                          <div className="col-xs-9">
                            <a href={donation.pageUri}>{donation.pageName}</a>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xs-3">
                            <Trans i18nKey="donationSummaryForm.charity">
                              Charity
                            </Trans>
                          </div>
                          <div className="col-xs-9">
                            <a href={donation.beneficiaryPageUri}>
                              {donation.beneficiaryName}
                            </a>
                          </div>
                        </div>
                      </div>
                    );
                  case donationTypes.crowdfunding:
                    return (
                      <div className="row">
                        <div className="col-xs-3">
                          <Trans i18nKey="donationSummaryForm.fundraiser">
                            Fundraiser
                          </Trans>
                        </div>
                        <div className="col-xs-9">
                          <a href={donation.beneficiaryPageUri}>
                            {donation.beneficiaryName}
                          </a>
                        </div>
                      </div>
                    );
                  case donationTypes.globalGivingProject:
                  case donationTypes.charityDirect:
                    return (
                      <div className="row">
                        <div className="col-xs-3">
                          <Trans i18nKey="donationSummaryForm.charity">
                            Charity
                          </Trans>
                        </div>
                        <div className="col-xs-9">
                          <a href={donation.beneficiaryPageUri}>
                            {donation.beneficiaryName}
                          </a>
                        </div>
                      </div>
                    );
                  case donationTypes.campaignDirect:
                    return (
                      <div className="row">
                        <div className="col-xs-3">
                          <Trans i18nKey="donationSummaryForm.campaign">
                            Campaign
                          </Trans>
                        </div>
                        <div className="col-xs-9">
                          <a href={donation.beneficiaryPageUri}>
                            {donation.beneficiaryName}
                          </a>
                        </div>
                      </div>
                    );
                  default:
                    return '';
                }
              })()}

              <div className="row">
                <div className="col-xs-3">
                  <Trans i18nKey="donationSummaryForm.amount">Amount</Trans>
                </div>
                <div className="col-xs-9">
                  <span className="donation-amount">
                    {getCurrencySymbol(donation.currencyCode)}
                    {donation.donationAmount &&
                      donation.donationAmount.toFixed(2)}{' '}
                    {donation.currencyCode}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-3">
                  <Trans i18nKey="donationSummaryForm.frequency">
                    Frequency
                  </Trans>
                </div>
                <div className="col-xs-9">
                  {donation.isScheduled ? 'Recuring' : 'One time'}
                </div>
              </div>
              <div className="row">
                <div className="col-xs-3">
                  <Trans i18nKey="donationSummaryForm.donationReference">
                    Donation reference
                  </Trans>
                </div>
                <div className="col-xs-9">{donation.donationReference}</div>
              </div>
            </div>

            <div className="donation-summary-displayed-info-wrapper">
              <h2>
                <Trans i18nKey="donationSummaryForm.displayedInformation">
                  Displayed information
                </Trans>
              </h2>
              <div className="row">
                <div className="col-xs-3">
                  <ProfileImageComponent
                    className="profile-image profile-menu__avatar"
                    imageUrl={donation.donorAvatarDisplayUrl}
                    width="30"
                    height="30"
                  />
                </div>
                <div className="col-xs-9 user-information">
                  <div>
                    {donation.donorDisplayName !== null ? (
                      donation.donorDisplayName
                    ) : (
                      <Trans i18nKey="common.anonymous">Anonymous</Trans>
                    )}
                  </div>
                  <div className="donation-message">
                    {donation.donationDisplayMessage}
                  </div>
                  <div>
                    <span className="donation-amount">
                      {donation.donationDisplayAmount &&
                        `${getCurrencySymbol(
                          donation.currencyCode,
                        )}${donation.donationDisplayAmount.toFixed(2)}`}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default DonationSummaryForm;
