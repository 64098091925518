/* eslint-disable jsx-a11y/no-static-element-interactions, react/prop-types */
/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import { paths } from 'constants';
import { track } from 'utils/koala-analytics';
import { Trans } from 'react-i18next';

const propTypes = {
  userAddressId: PropTypes.number,
  addressLine1: PropTypes.string,
  addressLine2: PropTypes.string,
  town: PropTypes.string,
  county: PropTypes.string,
  country: PropTypes.string,
  postCode: PropTypes.string,
};

const defaultProps = {
  userAddressId: undefined,
  addressLine1: undefined,
  addressLine2: undefined,
  town: undefined,
  county: undefined,
  country: undefined,
  postCode: undefined,
};

const trackAddAddress = () => {
  const trackingData = {
    event: 'link click',
    subtype: 'hyperlink',
    page_section: 'personal details > address',
    event_value: 'add',
  };
  track(trackingData);
};

const trackEditAddress = () => {
  const trackingData = {
    event: 'link click',
    subtype: 'hyperlink',
    page_section: 'personal details > address',
    event_value: 'edit',
  };
  track(trackingData);
};

const addAddress = () => (
  <section className="details-block">
    <p className="jg-text--medium-light jg-space-pxs">
      <Trans i18nKey="personalDetails.address">Address</Trans>
    </p>
    <p className="jg-text--dark jg-space-pxs">
      <Link
        to={paths.Address}
        onClick={trackAddAddress}
        className="qa-link__address"
      >
        <Trans i18nKey="personalDetails.addYourAddress">Add your address</Trans>
      </Link>
    </p>
  </section>
);

const updateAddress = props => {
  const {
    addressLine1,
    addressLine2,
    town,
    postCode,
    country,
    county,
    state,
  } = props;
  return (
    <section className="details-block address">
      <div className="jg-space-phsm@sm jg-space-phn@lg">
        <p className="jg-text--medium-light jg-space-mbxs">
          <Trans i18nKey="personalDetails.address">Address</Trans>
        </p>
        <div className="data-value">
          {addressLine1 && <div>{addressLine1}</div>}
          {addressLine2 && <div>{addressLine2}</div>}
          {town && <div>{town}</div>}
          {county && <div>{county}</div>}
          {state && <div>{state}</div>}
          {postCode && <div>{postCode}</div>}
          {country && <div>{country}</div>}
        </div>
        <p className="jg-text--dark jg-space-mbxs">
          <Link
            to={paths.Address}
            onClick={trackEditAddress}
            className="qa-link__address"
          >
            <Trans i18nKey="personalDetails.editAddress">
              Edit your address
            </Trans>
          </Link>
        </p>
      </div>
    </section>
  );
};

const Address = props =>
  props.userAddressId !== undefined ? updateAddress(props) : addAddress();

Address.propTypes = propTypes;

Address.defaultProps = defaultProps;

export default Address;
