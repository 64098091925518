import './recurringPayments.scss';

import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import RecurringCardPaymentsModal, {
  ModalProps,
} from './RecurringCardPaymentsModal';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '@justgiving/loading';
import RecurringPaymentHeader from './RecurringPaymentHeader';
import RecurringPaymentItem from './RecurringPaymentsItem';
import { State } from '../../../types/state';
import { getRecurringPayments } from '../../actions';
import { useTranslation } from 'react-i18next';

type DispatchStateAction = {
  type: 'open_cancel_modal' | 'close_cancel_modal';
  paymentData?: ModalPaymentData;
};
export type ModalPaymentData = Omit<ModalProps, 'close'>;
type ComponentState = {
  isCancelModalOpen: boolean;
  paymentData?: ModalPaymentData;
};

const RecurringCardPayments: FunctionComponent = () => {
  const { t } = useTranslation();
  const active = useSelector(
    (state: State) => state.app.recurringPayments.data.active,
  );

  const initialState = { isCancelModalOpen: false };

  const [{ isCancelModalOpen, paymentData }, dispatchState] = useReducer(
    (state: ComponentState, action: DispatchStateAction) => {
      switch (action.type) {
        case 'open_cancel_modal':
          return {
            isCancelModalOpen: true,
            paymentData: action.paymentData,
          };
        case 'close_cancel_modal':
          return {
            isCancelModalOpen: false,
          };
        default:
          return state;
      }
    },
    initialState,
  );

  const openCancelModal = (paymentData: ModalPaymentData) =>
    dispatchState({ type: 'open_cancel_modal', paymentData });

  const activePayments = useMemo(
    () =>
      active.map(paymentData => (
        <RecurringPaymentItem
          key={paymentData.id}
          openCancelModal={openCancelModal}
          predictedPayment={paymentData.predictedNextPayment}
          {...paymentData}
        />
      )),
    [active],
  );

  const isLoaded = useSelector(
    (state: State) => state.app.recurringPayments.status.isLoaded,
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getRecurringPayments());
  }, [dispatch]);

  if (!isLoaded) return <Loading />;

  if (activePayments.length === 0) {
    return (
      <p className="jg-text--medium-light">{t('recurringCard.noActive')}</p>
    );
  }

  return (
    <>
      <RecurringPaymentHeader />
      <ul className="monthly-donations__list">{activePayments}</ul>
      {isCancelModalOpen && paymentData && (
        <RecurringCardPaymentsModal
          close={() => dispatchState({ type: 'close_cancel_modal' })}
          {...paymentData}
        />
      )}
    </>
  );
};

export default RecurringCardPayments;
