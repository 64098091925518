import { GraphQLClient } from 'graphql-request';
import auth from '../utils/auth';
import config from '../config';
import { createApi } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';

const client = new GraphQLClient(config.graphql.baseUrl, {
  credentials: 'include',
  headers: {
    Authorization: `Bearer ${auth.getParsedAuthCookie()?.accessToken}`,
    'apollographql-client-name': 'JG.EUA.UI',
  },
});

// Base graphql API is used by graphql-code-generator's typescript-rtk-query
// plugin as a starting point to generate the endpoints and hooks outputted
// in the generated.ts file
// https://www.graphql-code-generator.com/plugins/typescript-rtk-query#extending-generated-code
// https://redux-toolkit.js.org/rtk-query/usage/queries
export const api = createApi({
  baseQuery: graphqlRequestBaseQuery({ client }),
  endpoints: () => ({}),
});
