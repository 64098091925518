import { checkLogin } from 'actions';
import { getBearerHeaders } from 'utils';
import withRetry from 'actions/api/withRetry';

export default (baseUrl, resourcePath) => dispatch =>
  dispatch(checkLogin()).then(() => {
    let promises = [];

    if (typeof resourcePath === 'object' && resourcePath.length) {
      promises = resourcePath.map(path => {
        const promise = () =>
          fetch(`${baseUrl}${path}`, {
            method: 'GET',
            headers: getBearerHeaders(),
          }).then(res => Promise.all([res, res.json()]));

        return withRetry(promise);
      });
    } else {
      const promise = () =>
        fetch(`${baseUrl}${resourcePath}`, {
          method: 'GET',
          headers: getBearerHeaders(),
        }).then(res => Promise.all([res, res.json()]));
      promises.push(withRetry(promise));
    }

    return Promise.all(promises)
      .then(res => Promise.all(res))
      .catch(() => Promise.reject());
  });
