// TODO: move to toolkit. Copied from DNA https://source.justgiving.com/JustGiving/GG.FrontEnd.DNA.react.intl/blob/master/components/intl/localiser.js
export const getCurrencySymbol = currencyCode => {
  switch (currencyCode) {
    case 'AED':
      return 'د.إ';
    case 'AUD':
      return '$';
    case 'NZD':
      return '$';
    case 'CAD':
      return '$';
    case 'EUR':
      return '€';
    case 'GBP':
      return '£';
    case 'HKD':
      return '$';
    case 'SGD':
      return '$';
    case 'USD':
      return '$';
    case 'ZAR':
      return 'R';
    default:
      return '£';
  }
};

export const getCurrencyLocale = amount =>
  Number(amount).toLocaleString({}, { minimumFractionDigits: 2 });
