import { ReactNode } from 'react';

export const globalAlertActions = {
  set: 'globalAlert/set',
  remove: 'globalAlert/remove',
};

export const setGlobalAlert = (
  message: string | ReactNode,
  type = 'success',
) => ({
  type: globalAlertActions.set,
  payload: { message, type },
});
export const removeGlobalAlert = () => ({ type: globalAlertActions.remove });
