export default (type, rawLog) => dispatch => {
  const log = JSON.parse(JSON.stringify(rawLog));
  const error = log.error || {};
  const IS_NODE =
    typeof document === 'undefined' ||
    (process && process.env && process.env.NODE_ENV === 'test');
  dispatch({
    type: `LOG_${type}`,
    payload: {
      error,
      meta: { ...log, environment: IS_NODE ? 'server' : 'client' },
    },
  });
};
