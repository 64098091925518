import { ActionTypes as types } from 'constants';
import { locale, countryCode } from 'i18n';

const initialState = {
  locale,
  isoCountryCode: countryCode,
};

const localeData = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_COUNTRY_CODE: {
      return Object.assign({}, state, {
        ...state,
        isoCountryCode: action.payload,
      });
    }
    default:
      return state;
  }
};

export default localeData;
