import React, { FunctionComponent } from 'react';
import PageHeader from '../components/PageHeader/PageHeader';
import RecurringCardPayments from '../components/RecurringPayments/RecurringCardPayments';
import DirectDebit from '../components/RecurringPayments/DirectDebit';
import { useTranslation } from 'react-i18next';
import { features } from '../constants';

export const MonthlyDonations: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <div className="wrapper-main">
      <PageHeader>
        <h1 className="qa-page__heading">{t('MonthlyDonations.title')}</h1>
        <p className="jg-text--medium-light">
          {t('MonthlyDonations.description')}
        </p>
      </PageHeader>
      {features.directDebit && (
        <div className="wrapper-recurringPayments">
          <h2 className="jg-space-mblg jg-h3">{t('directDebit.title')}</h2>
          <DirectDebit />
        </div>
      )}
      <div className="wrapper-recurringPayments">
        <h2 className="jg-space-mblg jg-h3">{t('recurringCard.title')}</h2>
        <RecurringCardPayments />
      </div>
    </div>
  );
};

export default MonthlyDonations;
