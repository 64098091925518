/* eslint-disable max-len */
import React, { Component } from 'react';

import Address from './Address';
import Email from './Email';
import FormError from '../../components/Alert/FormError';
import Loading from '@justgiving/loading';
import Name from './Name';
import PageHeader from '../../components/PageHeader/PageHeader';
import { PropTypes } from 'prop-types';
import { Trans } from 'react-i18next';
import { getCountryNameByIsoCode } from 'i18n/country-map';
import DateOfBirth from './DateOfBirth';
import PhoneNumber from './PhoneNumber';

const propTypes = {
  fetchData: PropTypes.func.isRequired,
  trackPageView: PropTypes.func.isRequired,
  status: PropTypes.shape({
    isFetching: PropTypes.bool,
    fetchError: PropTypes.bool,
  }).isRequired,
  user: PropTypes.shape({
    Title: PropTypes.string,
    FirstName: PropTypes.string,
    LastName: PropTypes.string,
    EmailAddress: PropTypes.string,
    DateOfBirth: PropTypes.string,
    PhoneNumber: PropTypes.string,
    AddressLine1: PropTypes.string,
    AddressLine2: PropTypes.string,
    Town: PropTypes.string,
    County: PropTypes.string,
    Country: PropTypes.string,
    PostCode: PropTypes.string,
    ProfileImage: PropTypes.string,
  }),
  userAddress: PropTypes.shape({
    AddressLine1: PropTypes.string,
    AddressLine2: PropTypes.string,
    Town: PropTypes.string,
    County: PropTypes.string,
    Country: PropTypes.string,
    PostCode: PropTypes.string,
    CountryCode: PropTypes.string,
    State: PropTypes.string,
    UserAddressId: PropTypes.string,
  }),
  localeData: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
};

const defaultProps = {
  user: PropTypes.shape({
    Title: undefined,
    FirstName: undefined,
    LastName: undefined,
    EmailAddress: undefined,
    DateOfBirth: undefined,
    PhoneNumber: undefined,
    AddressLine1: undefined,
    AddressLine2: undefined,
    Town: undefined,
    County: undefined,
    Country: undefined,
    PostCode: undefined,
    ProfileImage: undefined,
  }),
  userAddress: PropTypes.shape({
    AddressLine1: undefined,
    AddressLine2: undefined,
    Town: undefined,
    County: undefined,
    Country: undefined,
    PostCode: undefined,
  }),
};

class PersonalDetailsComponent extends Component {
  componentDidMount() {
    const { trackPageView, fetchData } = this.props;
    fetchData();
    trackPageView('personal details', 'personal details');
  }

  getPageComponents() {
    const { user, userAddress } = this.props;
    const countryCode = userAddress.CountryCode;
    const country = getCountryNameByIsoCode(countryCode);
    const hasValue = value => Boolean(value);
    const userDOB = new Date(user.DateOfBirth).toLocaleDateString();
    const hasPhoneNumber = hasValue(user.PhoneNumber);
    const hasDateOfBirth = hasValue(user.DateOfBirth);

    return (
      <>
        <Name
          title={user.Title}
          first={user.FirstName}
          last={user.LastName}
          profilePictureUrl={user.ProfileImage}
        />
        <Email address={user.EmailAddress} />
        <Address
          addressLine1={userAddress.AddressLine1}
          addressLine2={userAddress.AddressLine2}
          town={userAddress.Town}
          county={userAddress.County}
          country={country}
          state={userAddress.State}
          postCode={userAddress.PostCode}
          userAddressId={userAddress.UserAddressId}
        />
        {hasDateOfBirth && <DateOfBirth dateOfBirth={userDOB} />}
        {hasPhoneNumber && <PhoneNumber phoneNumber={user.PhoneNumber} />}
      </>
    );
  }

  render() {
    const { status } = this.props;

    if (status.isFetching) {
      return (
        <div className="jg-space-mtxl">
          <Loading size="large" />
        </div>
      );
    }

    const PageComponents = this.getPageComponents();

    let content = PageComponents;
    if (status.fetchError) {
      content = (
        <div className="jg-space-mhlg@lg">
          <FormError error />
        </div>
      );
    }

    return (
      <div className="jg-background--white jg-bd@lg jg-bd--very-light-grey">
        <div className="jg-space-psm jg-space-plg@lg">
          <PageHeader>
            <h1 className="qa-page__heading">
              <Trans i18nKey="personalDetails.title">Personal details</Trans>
            </h1>
            <p className="jg-text--medium-light">
              <Trans i18nKey="personalDetails.text">
                You can update your name, address, mobile number and email
                address here.
              </Trans>
            </p>
          </PageHeader>
        </div>
        {content}
      </div>
    );
  }
}

PersonalDetailsComponent.propTypes = propTypes;

PersonalDetailsComponent.defaultProps = defaultProps;

export default PersonalDetailsComponent;
