import { getFromDonationHistoryServiceApi, logError } from 'actions';

import { auth } from '../../utils';
import { ActionTypes as types } from 'constants';
import { updateFetchAppError } from 'actions/setters/updateAppErrors';

const log = { component: 'getDonationSummary' };

// eslint-disable-next-line max-len
export default (
  id,
  _getFromDonationHistoryServiceApi = getFromDonationHistoryServiceApi,
  _logError = logError,
) => dispatch => {
  dispatch({
    type: types.FETCH_DONATION_SUMMARY,
  });

  const donationSummaryResource = `/v1/donors/${
    auth.getAccessToken().guid
  }/donations/${id}/summary`;
  return dispatch(_getFromDonationHistoryServiceApi(donationSummaryResource))
    .then(([res, json]) => {
      if (res.ok) {
        dispatch({
          type: types.FETCH_DONATION_SUMMARY_SUCCESS,
          payload: json,
        });
      } else if (json && json.Errors && json.Errors.length > 0) {
        const error = json.Errors[0].ErrorMessage;
        dispatch(updateFetchAppError());
        dispatch({ type: types.FETCH_DONATION_SUMMARY_FAILURE });
        dispatch(
          _logError(types.UPDATE_APP_STATUS_FETCH_ERROR, { ...log, error }),
        );
      } else {
        dispatch(updateFetchAppError());
        dispatch({ type: types.FETCH_DONATION_SUMMARY_FAILURE });
        dispatch(
          _logError(types.UPDATE_APP_STATUS_FETCH_ERROR, { ...log, res }),
        );
      }
    })
    .catch(error => {
      dispatch(updateFetchAppError());
      dispatch({ type: types.FETCH_DONATION_SUMMARY_FAILURE });
      dispatch(
        _logError(types.UPDATE_APP_STATUS_FETCH_ERROR, { ...log, error }),
      );
    });
};
