import './UploadProfileImage.scss';

import ImageEditor, { Image } from '@justgiving/image-editor';
import React, { useState } from 'react';
import {
  useRemoveImageMutation,
  useUploadImageMutation,
} from '../../api/profileServiceApi';

import Loading from '@justgiving/loading';
import getUser from '../../actions/getters/getUser';
import { useDispatch } from 'react-redux';
import { useToast } from '@justgiving/toast';
import { useTranslation } from 'react-i18next';

interface UploadProfileImageProps {
  onImageUploaded?: () => void;
  onImageRemoved?: () => void;
  currentImageUrl?: string;
}

const UploadProfileImage: React.FC<UploadProfileImageProps> = ({
  onImageUploaded = () => {},
  onImageRemoved = () => {},
  currentImageUrl,
}) => {
  const { t } = useTranslation();
  const [imagePreview, setImagePreview] = useState<Image>();
  const dispatch = useDispatch();
  const [
    uploadImage,
    { isLoading: uploadImageLoading },
  ] = useUploadImageMutation();
  const [
    removeImage,
    { isLoading: removeImageLoading },
  ] = useRemoveImageMutation();

  const { showToast } = useToast();

  const handleImageUpload = async () => {
    if (!imagePreview?.blob) {
      throw new Error('An image has not been selected');
    }

    try {
      await uploadImage(imagePreview.blob).unwrap();
      showToast({
        message: t('imageUpload.success'),
      });
      dispatch(getUser());
      onImageUploaded();
    } catch {
      showToast({
        message: t('common.tryAgain'),
        type: 'warning',
      });
    }
  };

  const handleRemoveImage = async () => {
    try {
      await removeImage().unwrap();
      showToast({
        message: t('imageUpload.removeSuccess'),
      });
      dispatch(getUser());
      onImageRemoved();
    } catch {
      showToast({
        message: t('common.tryAgain'),
        type: 'warning',
      });
    }
  };

  const isLoading = uploadImageLoading || removeImageLoading;

  return (
    <div className="upload-profile-image">
      <h1 className="heading">{t('imageUpload.title')}</h1>
      <p className="subtitle">{t('imageUpload.subtitle')}</p>
      <ImageEditor
        onEditChange={setImagePreview}
        uploadButtonLabel={
          isLoading ? (
            <Loading size="small" colour="white" />
          ) : (
            t('common.upload')
          )
        }
        removeButtonLabel={t('imageUpload.removeLabel')}
        image={imagePreview?.base64}
        mode={imagePreview ? 'edit' : 'pick'}
        editData={{
          outputHeight: 450,
          outputWidth: 450,
        }}
      />
      {imagePreview && (
        <button
          className="jg-btn"
          onClick={handleImageUpload}
          disabled={isLoading}
        >
          {isLoading ? (
            <Loading size="small" colour="white" />
          ) : (
            t('common.upload')
          )}
        </button>
      )}
      {currentImageUrl && !imagePreview && (
        <button
          className="jg-text--link remove-image-button"
          onClick={handleRemoveImage}
          disabled={isLoading}
        >
          {t('imageUpload.removeImage')}
        </button>
      )}
    </div>
  );
};

export default UploadProfileImage;
