import Analytics from '@justgiving/koala-front-end-analytics';
import config from '../config';

const {
  koala: { baseUrl: KOALA_API_URL },
} = config;

const koala = new Analytics(
  {
    apiUrl: `${KOALA_API_URL}/events`,
    isDebug: Boolean(config.LOCAL),
  },
  1,
);

export const setKoalaAnalyticsConsts = () => {
  koala.set({
    page_type: 'end user account',
    schema_id: 'eventbase',
  });
};

export const track = data => {
  koala.track(data);
};
