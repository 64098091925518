import React, { FunctionComponent, ReactNode } from 'react';

import PageHeader from '../PageHeader/PageHeader';
import { useTranslation } from 'react-i18next';

export const Content: FunctionComponent<ContentProps> = ({
  titleKey,
  subTitleKey,
  children,
  className = '',
}) => {
  const { t } = useTranslation();

  return (
    <div className={`wrapper-main ${className}`}>
      <PageHeader>
        <h1 className="qa-page__heading cp-heading-large">{t(titleKey)}</h1>
        {subTitleKey && (
          <p className="jg-text--medium-light">{t(subTitleKey)}</p>
        )}
      </PageHeader>
      {children}
    </div>
  );
};

interface ContentProps {
  titleKey: string;
  subTitleKey?: string;
  className?: string;
  children: ReactNode;
}
