import { api as generatedApi } from './generated';

// Tags determine whether a mutation for one endpoint intends to invalidate
// some data that was provided by a query from another endpoint
// https://redux-toolkit.js.org/rtk-query/usage/automated-refetching#definitions
enum Tags {
  IsStravaConnected = 'IsStravaConnected',
}

// The API outputted by graphql-code-generator's typescript-rtk-query plugin
// can be enhanced to add additional functionality
// https://www.graphql-code-generator.com/plugins/typescript-rtk-query#extending-generated-code
export const graphqlApi = generatedApi.enhanceEndpoints({
  addTagTypes: [Tags.IsStravaConnected],
  endpoints: {
    disconnectStrava: {
      invalidatesTags: [Tags.IsStravaConnected],
    },
  },
});
