import React, {
  FunctionComponent,
  useCallback,
  useReducer,
  useState,
} from 'react';

import Alert from '@justgiving/alert';
import { DisconnectionModal } from './DisconnectionModal';
import { SettingSection } from './SettingSection';
import config from '../../config';
import disconnectFacebookThunk from '../../actions/app-settings/disconnectFacebook.thunk';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

type ComponentState = {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
};

interface FacebookSettingsProps {
  isConnected: boolean;
}

const FacebookSettings: FunctionComponent<FacebookSettingsProps> = ({
  isConnected,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const initialState = {
    isLoading: false,
    isError: false,
    isSuccess: false,
  };
  const reducer = (state: ComponentState, action: { type: string }) => {
    switch (action.type) {
      case 'DISCONNECTING':
        return { isSuccess: false, isLoading: true, isError: false };
      case 'DISCONNECTING_SUCCESS':
        return { isError: false, isLoading: false, isSuccess: true };
      case 'DISCONNECTING_FAILURE':
        return {
          isLoading: false,
          isSuccess: false,
          isError: true,
        };
      default:
        return state;
    }
  };

  const [{ isLoading, isError, isSuccess }, dispatchState] = useReducer(
    reducer,
    initialState,
  );

  const openModal = useCallback(() => setIsModalOpen(true), [setIsModalOpen]);
  const closeModal = useCallback(() => setIsModalOpen(false), [setIsModalOpen]);
  const disconnectFacebook = useCallback(async () => {
    try {
      dispatchState({ type: 'DISCONNECTING' });
      await dispatch(disconnectFacebookThunk());
      dispatchState({ type: 'DISCONNECTING_SUCCESS' });
    } catch {
      dispatchState({ type: 'DISCONNECTING_FAILURE' });
    }
  }, [dispatch, dispatchState]);

  if (isSuccess) {
    return (
      <Alert displayType="inline" type="success" className="jg-space-mvlg">
        {t('FacebookSettings.success')}
      </Alert>
    );
  }

  if (!isConnected) return null;

  return (
    <>
      <SettingSection
        actions={{ onBtnClick: openModal }}
        content={{
          heading: 'Facebook',
          image: {
            url: `${config.publicPath}Facebook.svg`,
            alt: 'Facebook logo',
          },
          description: t('FacebookSettings.description'),
          btnLabel: t('FacebookSettings.ButtonLabel'),
        }}
      />
      <DisconnectionModal
        actions={{
          onClose: closeModal,
          onBtnClick: disconnectFacebook,
        }}
        state={{
          isOpen: isModalOpen,
          isError,
          isLoading,
        }}
        content={{
          heading: t('FacebookSettings.ModalHeading'),
          description: [t('FacebookSettings.ModalDescription')],
          error: t('FacebookSettings.failure'),
          btnLabel: t('FacebookSettings.ModalButtonLabel'),
          btnNotNowLabel: t('FacebookSettings.ButtonNotNow'),
        }}
      />
    </>
  );
};

export default FacebookSettings;
