import Alert from '@justgiving/alert';
import React from 'react';
import { Trans } from 'react-i18next';

interface FormErrorProps {
  error?: string | boolean;
  comp?: JSX.Element;
  apiError?: string;
}

const FormError: React.FC<FormErrorProps> = ({
  error,
  comp,
  apiError,
  ...args
}) => {
  if (error && typeof error === 'string') {
    return (
      <Alert
        displayType="inline"
        className="jg-space-mbmd"
        type="warning"
        {...args}
      >
        {error}
      </Alert>
    );
  }

  if (apiError) {
    let errComp;

    switch (apiError) {
      case 'failed_to_fetch':
        errComp = (
          <Trans i18nKey="apiError.failed_to_fetch">
            Something went wrong. Please try again later.
          </Trans>
        );
        break;
      case 'failed_to_post':
        errComp = (
          <Trans i18nKey="apiError.failed_to_post">
            Something went wrong. Please try again later.
          </Trans>
        );
        break;
      case 'current_passwords_do_not_match':
        errComp = (
          <Trans i18nKey="apiError.current_passwords_do_not_match">
            Current password is incorrect.
          </Trans>
        );
        break;
      case 'the_requested_email_address_is_already_in_use':
        errComp = (
          <Trans i18nKey="apiError.the_requested_email_address_is_already_in_use">
            The requested email address is already in use.
          </Trans>
        );
        break;
      default:
        errComp = (
          <Trans i18nKey="formError.generic">
            Something went wrong. Please try again later.
          </Trans>
        );
        break;
    }

    return (
      <Alert
        displayType="inline"
        className="jg-space-mbmd"
        type="warning"
        {...args}
      >
        {errComp}
      </Alert>
    );
  }

  if (comp) {
    return (
      <Alert
        displayType="inline"
        className="jg-space-mbmd"
        type="warning"
        {...args}
      >
        {comp}
      </Alert>
    );
  }

  if (error) {
    return (
      <Alert
        displayType="inline"
        className="jg-space-mbmd"
        type="warning"
        {...args}
      >
        <Trans i18nKey="formError.generic">
          Something went wrong. Please try again later.
        </Trans>
      </Alert>
    );
  }

  return null;
};

export default FormError;
