import { ActionTypes as types } from 'constants';

const initialState = {
  savedPaymentCards: [],
  isLoaded: false,
  isFetching: false,
};

const deletePaymentCard = (savedPaymentCards, deleteId) => {
  return savedPaymentCards.filter(
    savedPaymentCard => savedPaymentCard.paymentCardId !== deleteId,
  );
};

const paymentMethods = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.FETCH_PAYMENT_METHODS:
    case types.DELETE_CARD:
      return Object.assign({}, state, {
        isLoaded: false,
        isFetching: true,
      });
    case types.FETCH_PAYMENT_METHODS_SUCCESS:
      return Object.assign(
        {},
        { savedPaymentCards: action.payload._embedded['wallet:paymentCard'] },
        {
          isLoaded: true,
          isFetching: false,
        },
      );
    case types.DELETE_CARD_SUCCESS:
      return Object.assign(
        {},
        {
          savedPaymentCards: deletePaymentCard(
            state.savedPaymentCards,
            action.payload,
          ),
        },
        {
          isLoaded: true,
          isFetching: false,
        },
      );
    case types.FETCH_PAYMENT_METHODS_FAILURE:
    case types.DELETE_CARD_FAILURE:
      return Object.assign({}, state, {
        isLoaded: false,
        isFetching: false,
      });
    default:
      return state;
  }
};

export default paymentMethods;
