import { forms, ActionTypes as types } from 'constants';
import { getFromIamApi, logError } from 'actions';

import { auth } from '../../utils';
import { initialize } from 'redux-form';
import { updateFetchAppError } from 'actions/setters/updateAppErrors';
import moment from 'moment';

const log = { component: 'getUser' };

export default (_getFromIamApi = getFromIamApi, _logError = logError) => (
  dispatch,
  getState,
) => {
  dispatch({
    type: types.FETCH_USER,
  });

  const resourcePath = `/users/${auth.getAccessToken().guid}`;

  return dispatch(_getFromIamApi(resourcePath))
    .then(([res, json]) => {
      if (res.ok) {
        const userDOB = new Date(json.Result.DateOfBirth);
        const user = {
          ...json.Result,
          DayOfBirth: moment(userDOB).get('date'),
          MonthOfBirth: moment(userDOB).get('month') + 1,
          YearOfBirth: moment(userDOB).get('year'),
        };

        dispatch({
          type: types.FETCH_USER_SUCCESS,
          payload: user,
        });
        dispatch(initialize(forms.Name, user));
        dispatch(initialize(forms.Email, user));
        dispatch(initialize(forms.DateOfBirth, user));
        dispatch(initialize(forms.PhoneNumber, user));
      } else if (json && json.Errors && json.Errors.length > 0) {
        const error = json.Errors[0].ErrorMessage;
        dispatch(updateFetchAppError());
        dispatch({ type: types.FETCH_USER_FAILURE });
        dispatch(
          _logError(types.UPDATE_APP_STATUS_FETCH_ERROR, { ...log, error }),
        );
      } else {
        dispatch(updateFetchAppError());
        dispatch({ type: types.FETCH_USER_FAILURE });
        dispatch(
          _logError(types.UPDATE_APP_STATUS_FETCH_ERROR, { ...log, res }),
        );
      }
    })
    .catch(error => {
      dispatch(updateFetchAppError());
      dispatch({ type: types.FETCH_USER_FAILURE });
      dispatch(
        _logError(types.UPDATE_APP_STATUS_FETCH_ERROR, { ...log, error }),
      );
    });
};
