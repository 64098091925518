import { auth, getBearerHeaders } from '../../utils';

import config from '../../config';
import { logError } from '..';
import { ActionTypes as types } from '../../constants';

const log = { component: 'paymentMethods' };

// eslint-disable-next-line max-len
export default (paymentCardId, _logError = logError) => dispatch => {
  const url = `${config.walletService.baseUrl}v1/donors/${
    auth.getAccessToken().guid
  }/wallet/paymentcards/${paymentCardId}`;
  const options = {
    method: 'DELETE',
    headers: getBearerHeaders(),
    credentials: 'include',
  };

  const deleteCardPromise = fetch(url, options)
    .then(res => {
      if (res.ok) {
        dispatch({
          type: types.DELETE_CARD_SUCCESS,
          payload: paymentCardId,
        });
        return Promise.resolve(res);
      }
      const error = new Error(res.statusText);
      return Promise.reject(error);
    })
    .catch(error => {
      dispatch({ type: types.DELETE_CARD_FAILURE });
      dispatch(
        _logError(types.UPDATE_APP_STATUS_FETCH_ERROR, { ...log, error }),
      );
      return Promise.reject(error);
    });

  dispatch({
    type: types.DELETE_CARD,
  });

  return deleteCardPromise;
};
