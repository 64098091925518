import { collectionLocationId, forms, ActionTypes as types } from 'constants';
import { getFromPreferencesServiceApi, logError } from 'actions';

import { auth } from '../../utils';
import { initialize } from 'redux-form';
import { updateFetchAppError } from 'actions/setters/updateAppErrors';

const log = { component: 'getUserNotifications' };

export default (
  _getFromPreferencesServiceApi = getFromPreferencesServiceApi,
  _logError = logError,
) => (dispatch, getState) => {
  dispatch({ type: types.FETCH_USER_NOTIFICATIONS });

  const { isoCountryCode, locale } = getState().app.localeData;

  const userGuid = auth.getAccessToken().guid;
  const combinedLocale =
    locale.indexOf('-') !== -1 ? locale : `${locale}_${isoCountryCode}`;

  const notificationsResource = `/users/${userGuid}/consents/aggregated?entityTypeId=3`;
  const consentsResource = `/ConsentStatements/${collectionLocationId}/${combinedLocale}`;

  return dispatch(
    _getFromPreferencesServiceApi([notificationsResource, consentsResource]),
  )
    .then(
      ([
        [notificationsResponse, notifications],
        [consentStatementsResponse, consentStatements],
      ]) => {
        const consents = {};
        let json = {};

        if (notifications.length && consentStatements.length) {
          let counter = 0;

          json = notifications.reduce((acc, notification) => {
            const matchedStatement = consentStatements.find(
              elm => elm.type === notification.consentStatementType,
            );

            consents[counter] = {
              ...notification,
              consentStatementText: matchedStatement.text,
              ConsentStatementId: matchedStatement.id,
            };
            acc[counter] = notification.consentGiven;
            counter += 1;
            return acc;
          }, {});
        }

        if (notificationsResponse.ok && consentStatementsResponse.ok) {
          dispatch({
            type: types.FETCH_USER_NOTIFICATIONS_SUCCESS,
            payload: {
              json,
              consents,
            },
          });
          dispatch(initialize(forms.Notifications, json));
        } else if (
          notifications &&
          consentStatements &&
          (notifications.Errors || consentStatements.Errors) &&
          (notifications.Errors.length > 0 ||
            consentStatements.Errors.length > 0)
        ) {
          const error =
            notifications.Errors && notifications.Errors.length
              ? notifications.Errors[0].ErrorMessage
              : consentStatements.Errors[0].ErrorMessage;
          dispatch(updateFetchAppError());
          dispatch({ type: types.FETCH_USER_NOTIFICATIONS_FAILURE });
          dispatch(
            _logError(types.UPDATE_APP_STATUS_FETCH_ERROR, { ...log, error }),
          );
        } else {
          dispatch(updateFetchAppError());
          dispatch({ type: types.FETCH_NOTIFICATIONS_FAILURE });
          dispatch(
            _logError(types.UPDATE_APP_STATUS_FETCH_ERROR, {
              ...log,
              notificationsResponse,
              consentStatementsResponse,
            }),
          );
        }
      },
    )
    .catch(error => {
      dispatch(updateFetchAppError());
      dispatch({ type: types.FETCH_USER_NOTIFICATIONS_FAILURE });
      dispatch(
        _logError(types.UPDATE_APP_STATUS_FETCH_ERROR, { ...log, error }),
      );
    });
};
