import React, { FunctionComponent, useCallback, useState } from 'react';

import Alert from '@justgiving/alert';
import { DisconnectionModal } from './DisconnectionModal';
import { SettingSection } from './SettingSection';
import config from '../../config';
import { useDisconnectStravaMutation } from '../../hooks/rtkQuery';
import { useTranslation } from 'react-i18next';

interface StravaSettingsProps {
  isConnected: boolean;
}

const StravaSettings: FunctionComponent<StravaSettingsProps> = ({
  isConnected,
}) => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = useCallback(() => setIsModalOpen(true), [setIsModalOpen]);
  const closeModal = useCallback(() => setIsModalOpen(false), [setIsModalOpen]);

  const [
    disconnectStrava,
    { isSuccess, isLoading, isError },
  ] = useDisconnectStravaMutation();

  if (isSuccess) {
    return (
      <Alert
        autoClose={5000}
        displayType="inline"
        type="success"
        className="jg-space-mvlg"
      >
        {t('StravaSettings.success')}
      </Alert>
    );
  }

  if (!isConnected) return null;

  return (
    <>
      <SettingSection
        actions={{ onBtnClick: openModal }}
        content={{
          heading: 'Strava',
          image: {
            url: `${config.publicPath}Strava.svg`,
            alt: 'Strava logo',
          },
          description: t('StravaSettings.description'),
          btnLabel: t('StravaSettings.ButtonLabel'),
        }}
      />
      <DisconnectionModal
        actions={{
          onClose: closeModal,
          onBtnClick: () => disconnectStrava(),
        }}
        state={{
          isOpen: isModalOpen,
          isError,
          isLoading,
        }}
        content={{
          heading: t('StravaSettings.ModalHeading'),
          description: [
            t('StravaSettings.ModalDescription-pt_1'),
            t('StravaSettings.ModalDescription-pt_2'),
            t('StravaSettings.ModalDescription-pt_3'),
          ],
          error: t('StravaSettings.failure'),
          btnLabel: t('StravaSettings.ModalButtonLabel'),
          btnNotNowLabel: t('StravaSettings.ModalButtonNotNow'),
        }}
      />
    </>
  );
};

export default StravaSettings;
