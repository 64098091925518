import { ActionTypes as types } from 'constants';

const initialState = {
  data: {},
  isLoaded: false,
  isFetching: false,
};

const updateData = (state, updatedData) =>
  Object.assign({}, state.data, { CanContact: updatedData });

const notifications = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.FETCH_NOTIFICATIONS:
      return Object.assign({}, state, {
        isLoaded: false,
        isFetching: true,
      });
    case types.FETCH_NOTIFICATIONS_SUCCESS:
      return Object.assign(
        {},
        { data: action.payload },
        {
          isLoaded: true,
          isFetching: false,
        },
      );
    case types.FETCH_NOTIFICATIONS_FAILURE:
      return Object.assign({}, state, {
        isLoaded: false,
        isFetching: false,
      });
    case types.UPDATE_NOTIFICATIONS:
      return Object.assign({}, state, {
        data: updateData(state, action.payload),
      });
    default:
      return state;
  }
};

export default notifications;
