import { ActionTypes as types, recurringPayments } from 'constants';
import checkLogin from '../setters/checkLogin';
import { sendToApi, logError } from 'actions';
import {
  resetAppErrors,
  updatePostAppError,
} from 'actions/setters/updateAppErrors';

const log = { component: 'cancelRecurringPayments' };

// eslint-disable-next-line max-len
export default (
  recurringPaymentId,
  _checkLogin = checkLogin,
  _logError = logError,
) => (dispatch, _getState, auth) => {
  dispatch({
    type: types.CANCEL_RECURRING_PAYMENTS,
  });
  const { guid: userGuid } = auth.getAccessToken();
  const recurringPaymentsResource = `${recurringPayments.baseUrl}/api/v2/recurring-payments/user/${userGuid}/mandate/${recurringPaymentId}`;

  return dispatch(_checkLogin())
    .then(() => sendToApi({}, recurringPaymentsResource, 'DELETE'))
    .then(([res, json]) => {
      if (res.ok) {
        dispatch({
          type: types.CANCEL_RECURRING_PAYMENTS_SUCCESS,
          payload: json.result,
        });
        dispatch(resetAppErrors());
      } else if (json && json.errorSummary) {
        const error = json.errorSummary;
        dispatch({ type: types.CANCEL_RECURRING_PAYMENTS_FAILURE });
        dispatch(updatePostAppError(error));
        dispatch(
          _logError(types.UPDATE_APP_STATUS_POST_ERROR, { ...log, error }),
        );
      } else {
        dispatch({ type: types.CANCEL_RECURRING_PAYMENTS_FAILURE });
        dispatch(updatePostAppError(res));
        dispatch(
          _logError(types.UPDATE_APP_STATUS_POST_ERROR, { ...log, res }),
        );
      }
    })
    .catch(error => {
      dispatch({ type: types.CANCEL_RECURRING_PAYMENTS_FAILURE });
      dispatch(updatePostAppError(error));
      dispatch(
        _logError(types.UPDATE_APP_STATUS_POST_ERROR, { ...log, error }),
      );
      return Promise.reject(error);
    });
};
