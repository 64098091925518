import React, { FunctionComponent } from 'react';

import { NavLink as ReactRouterNavLink } from 'react-router-dom';
import Svg from '@justgiving/svg';
import { chevronRightIcon } from '@justgiving/icons';

export const NavLink: FunctionComponent<NavLinkProps> = ({
  path,
  name,
  exact,
}) => (
  <ReactRouterNavLink
    to={path}
    activeClassName="nav__link--active"
    exact={exact}
    className="jg-space-psm jg-block jg-clearfix nav__link jg-color--empress jg-fill--empress"
  >
    <div
      className={`nav__link__text jg-pull-left qa-nav__link-${path.substring(
        1,
      )}`}
    >
      {name}
    </div>{' '}
    <Svg markup={chevronRightIcon} className="jg-icon--slim jg-pull-right" />
  </ReactRouterNavLink>
);

interface NavLinkProps {
  path: string;
  name: string;
  exact?: boolean;
}
