import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import config from '../config';

export const profileServiceApi = createApi({
  reducerPath: 'profileService',
  baseQuery: fetchBaseQuery({
    baseUrl: config.profile.baseUrl,
    credentials: 'include',
  }),
  endpoints: builder => ({
    getUserTotals: builder.query<UserTotals, void>({
      query: () => 'summary/get-ask-and-donation-totals',
    }),
    uploadImage: builder.mutation<ImageUploadResponse, Blob>({
      query: image => {
        const data = new FormData();
        data.append('file', image);

        return {
          url: '/image/upload-image',
          body: data,
          method: 'POST',
        };
      },
    }),
    removeImage: builder.mutation<void, void>({
      query: () => ({
        url: 'image/remove-image',
        method: 'POST',
      }),
    }),
    getInactiveAsks: builder.query<GetInactiveAsksResponse, void>({
      query: () => ({
        url: 'ask/get-inactive-asks',
      }),
    }),
  }),
});

export const {
  useGetUserTotalsQuery,
  useUploadImageMutation,
  useRemoveImageMutation,
  useGetInactiveAsksQuery,
} = profileServiceApi;

type Total = {
  total: string;
  currencycode: string;
};

export type UserTotals = {
  currencycode: string;
  given: string;
  raised: string;
  othercurrenciesgiven: Total[];
  othercurrenciesraised: Total[];
};

type ImageUploadResponse = {
  url: string;
  success: boolean;
  path: string;
};

export type Ask = {
  title: string;
  url: string;
  imageUrl: string;
  amountRaised: string;
  target: string;
  hasTarget: boolean;
  currency: string;
  isYimby: boolean;
  progress: string;
  yimbyPitchFor: string;
  yimbyPitchBecause?: any;
  beneficiaryName: string;
  askStatus: string;
  editUrl: string;
};

type GetInactiveAsksResponse = {
  asks: Ask[];
  startUrl: string;
};
