import './app-settings.scss';

import React, { FunctionComponent, useEffect } from 'react';

import FacebookSettings from './FacebookSettings';
import { FitbitSettings } from './FitbitSettings';
import Loading from '@justgiving/loading';
import StravaSettings from './StravaSettings';
import getUser from '../../actions/getters/getUser';
import { useAppSelector } from '../../hooks/redux';
import { useDispatch } from 'react-redux';
import { useThirdPartyApplicationsQuery } from '../../hooks/rtkQuery';
import { useTranslation } from 'react-i18next';

export const Apps: FunctionComponent = () => {
  const { t } = useTranslation();

  const { isFacebookConnected } = useFacebook();
  const {
    isStravaConnected,
    isFitbitConnected,
    isThirdPartyApplicationsLoading,
  } = useThirdPartyApplications();
  useUserData();

  if (isThirdPartyApplicationsLoading) return <Loading />;

  if (!isFacebookConnected && !isStravaConnected && !isFitbitConnected) {
    return (
      <p className="jg-text--medium-light">
        {t('Apps.descriptionNotConnected')}
      </p>
    );
  }

  return (
    <div>
      <p className="jg-text--medium-light">{t('Apps.descriptionConnected')}</p>
      <FacebookSettings isConnected={isFacebookConnected} />
      <StravaSettings isConnected={isStravaConnected} />
      <FitbitSettings isConnected={isFitbitConnected} />
    </div>
  );
};

function useFacebook() {
  const { HasFacebookCredentials, HasJustGivingCredentials } = useAppSelector(
    state => ({
      HasFacebookCredentials: state.app.user.data.HasFacebookCredentials,
      HasJustGivingCredentials: state.app.user.data.HasJustGivingCredentials,
    }),
  );

  return {
    isFacebookConnected: HasFacebookCredentials && HasJustGivingCredentials,
  };
}

function useUserData() {
  const { isUserDataLoaded } = useAppSelector(state => ({
    isUserDataLoaded: state.app.user.isLoaded,
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isUserDataLoaded) dispatch(getUser());
  }, [isUserDataLoaded, dispatch]);

  return { isUserDataLoaded };
}

function useThirdPartyApplications() {
  const { data, isLoading } = useThirdPartyApplicationsQuery();

  const isStravaConnected =
    data?.viewer?.thirdPartyApplications?.strava?.isConnected ?? false;
  const isFitbitConnected =
    data?.viewer?.thirdPartyApplications?.fitbit?.isConnected ?? false;

  return {
    isStravaConnected,
    isFitbitConnected,
    isThirdPartyApplicationsLoading: isLoading,
  };
}
