import React, { FunctionComponent } from 'react';
import { Content } from '../components/Layout/Content';
import { Apps } from '../components/AppSettings/Apps';

const AppSettings: FunctionComponent = () => {
  return (
    <Content titleKey="Apps.heading">
      <Apps />
    </Content>
  );
};

export default AppSettings;
