import { ActionTypes as types } from 'constants';

const initialState = {
  data: {
    active: [],
    cancelled: [],
  },
  status: {
    isFetching: false,
    isLoaded: false,
    isCancelling: false,
    isCancelled: false,
  },
};

const cancelRecurringPayment = (data, cancelData) => {
  // find the item with previous status
  let recurringPaymentsToCancel = data.active.find(
    recurringPayment => recurringPayment.id === cancelData.id,
  );

  // make a shallow copy
  recurringPaymentsToCancel = { ...recurringPaymentsToCancel };
  recurringPaymentsToCancel.status = cancelData.nextStatus;

  return {
    active: data.active.filter(x => x.id !== cancelData.id),
    cancelled: [recurringPaymentsToCancel, ...data.cancelled],
  };
};

const recurringPayments = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.FETCH_RECURRING_PAYMENTS:
      return Object.assign({}, state, {
        status: {
          ...state.status,
          isFetching: true,
          isLoaded: false,
        },
      });
    case types.FETCH_RECURRING_PAYMENTS_SUCCESS:
      return Object.assign(
        {},
        { data: action.payload },
        {
          status: {
            ...state.status,
            isFetching: false,
            isLoaded: true,
          },
        },
      );
    case types.FETCH_RECURRING_PAYMENTS_FAILURE:
      return Object.assign({}, state, {
        status: {
          ...state.status,
          isFetching: false,
          isLoaded: false,
        },
      });
    case types.CANCEL_RECURRING_PAYMENTS:
      return Object.assign({}, state, {
        status: {
          ...state.status,
          isCancelling: true,
          isCancelled: false,
        },
      });
    case types.CANCEL_RECURRING_PAYMENTS_SUCCESS:
      return Object.assign(
        {},
        { data: cancelRecurringPayment(state.data, action.payload) },
        {
          status: {
            ...state.status,
            isCancelling: false,
            isCancelled: true,
          },
        },
      );
    case types.CANCEL_RECURRING_PAYMENTS_FAILURE:
      return Object.assign({}, state, {
        status: {
          ...state.status,
          isCancelling: false,
          isCancelled: false,
        },
      });
    default:
      return state;
  }
};

export default recurringPayments;
