import { ActionTypes as types } from 'constants';

const initialState = {
  data: {},
  isoStateCode: null,
  isFetching: false,
  isLoaded: false,
};

const updateData = (state, updatedData) =>
  Object.assign({}, state.data, updatedData);

const userAddress = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.FETCH_USER_ADDRESS:
      return Object.assign({}, state, {
        isFetching: true,
        isLoaded: false,
      });
    case types.FETCH_USER_ADDRESS_SUCCESS:
      return Object.assign(
        {},
        { data: action.payload },
        {
          isFetching: false,
          isLoaded: true,
        },
      );
    case types.FETCH_USER_ADDRESS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isLoaded: false,
      });
    case types.UPDATE_USER_ADDRESS:
      return Object.assign(
        {},
        state,
        { isLoaded: false },
        { data: updateData(state, action.payload) },
      );
    case types.UPDATE_STATE_CODE:
      return Object.assign({}, state, {
        data: updateData(state, { County: action.payload }),
      });
    default:
      return state;
  }
};

export default userAddress;
