import { ActionTypes as types } from 'constants';

const initialState = {
  data: [],
  status: {
    isFetching: false,
    error: false,
  },
};

const findAddresses = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.FETCH_FIND_ADDRESSES:
      return Object.assign({}, state, {
        status: {
          isFetching: true,
          error: false,
        },
      });
    case types.FETCH_FIND_ADDRESSES_SUCCESS:
      return Object.assign(
        {},
        { data: action.payload },
        {
          status: {
            isFetching: false,
            error: false,
          },
        },
      );
    case types.FETCH_FIND_ADDRESSES_FAILURE:
      return Object.assign({}, state, {
        status: {
          isFetching: false,
          error: true,
        },
      });
    default:
      return state;
  }
};

export default findAddresses;
