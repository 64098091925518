import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { store } from '../configureStore';

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;

export const useThunkDispatch = () => useDispatch<ThunkAppDispatch>();
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
