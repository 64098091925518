import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Alert from '@justgiving/alert';
import { State } from '../../../types/state';
import { removeGlobalAlert } from './GlobalAlert.actions';

const GlobalAlert: FunctionComponent = () => {
  const payload = useSelector((state: State) => state.globalAlert);

  const dispatch = useDispatch();

  useEffect(() => {
    if (payload !== null) window.scrollTo(0, 0);
  }, [payload]);

  if (!payload) return null;

  return (
    <Alert type={payload.type} onClose={() => dispatch(removeGlobalAlert())}>
      {payload.message}
    </Alert>
  );
};

export default GlobalAlert;
