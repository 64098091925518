import config from '../config';
import { useCallback } from 'react';
import { useLocation } from 'react-router';

const useRedirectToLogin = () => {
  const { pathname } = useLocation();
  const pathPrefix = config.pathPrefix.replace(/\//g, '');

  return useCallback(
    () =>
      window.location.assign(
        `${config.loginUrl}&returnUrl=/${pathPrefix}${pathname}`,
      ),
    [pathname, pathPrefix],
  );
};

export default useRedirectToLogin;
