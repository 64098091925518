import { initialize } from 'redux-form';
import { ActionTypes as types, forms } from 'constants';
import { getFromProfileApi, logError } from 'actions';
import { updateFetchAppError } from 'actions/setters/updateAppErrors';

const log = { component: 'getNotifications' };

// eslint-disable-next-line max-len
export default (
  _getFromProfileApi = getFromProfileApi,
  _logError = logError,
) => (dispatch, getState) => {
  const { isLoaded } = getState().app.notifications;

  if (isLoaded) return Promise.resolve('isLoaded');

  dispatch({
    type: types.FETCH_NOTIFICATIONS,
  });

  const notificationsResource = 'user-status/consent';
  return dispatch(_getFromProfileApi(notificationsResource))
    .then(([res, json]) => {
      if (res.ok) {
        dispatch({
          type: types.FETCH_NOTIFICATIONS_SUCCESS,
          payload: json,
        });
        dispatch(initialize(forms.Notifications, json));
      } else if (json && json.Errors && json.Errors.length > 0) {
        const error = json.Errors[0].ErrorMessage;
        dispatch(updateFetchAppError());
        dispatch({ type: types.FETCH_NOTIFICATIONS_FAILURE });
        dispatch(
          _logError(types.UPDATE_APP_STATUS_FETCH_ERROR, { ...log, error }),
        );
      } else {
        dispatch(updateFetchAppError());
        dispatch({ type: types.FETCH_NOTIFICATIONS_FAILURE });
        dispatch(
          _logError(types.UPDATE_APP_STATUS_FETCH_ERROR, { ...log, res }),
        );
      }
    })
    .catch(error => {
      dispatch(updateFetchAppError());
      dispatch({ type: types.FETCH_NOTIFICATIONS_FAILURE });
      dispatch(
        _logError(types.UPDATE_APP_STATUS_FETCH_ERROR, { ...log, error }),
      );
    });
};
