import React, { FunctionComponent, useCallback, useState } from 'react';

import Alert from '@justgiving/alert';
import { DisconnectionModal } from './DisconnectionModal';
import { SettingSection } from './SettingSection';
import config from '../../config';
import { useDisconnectFitbitMutation } from '../../api/generated';
import { useTranslation } from 'react-i18next';

interface FitbitSettingsProps {
  isConnected: boolean;
}

export const FitbitSettings: FunctionComponent<FitbitSettingsProps> = ({
  isConnected,
}) => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = useCallback(() => setIsModalOpen(true), [setIsModalOpen]);
  const closeModal = useCallback(() => setIsModalOpen(false), [setIsModalOpen]);

  const [
    disconnectFitbit,
    { isSuccess, isLoading, isError },
  ] = useDisconnectFitbitMutation();

  if (isSuccess) {
    return (
      <Alert
        autoClose={5000}
        displayType="inline"
        type="success"
        className="jg-space-mvlg"
      >
        {t('FitbitSettings.success')}
      </Alert>
    );
  }

  if (!isConnected) return null;

  return (
    <>
      <SettingSection
        actions={{ onBtnClick: openModal }}
        content={{
          heading: 'Fitbit',
          image: {
            url: `${config.publicPath}Fitbit.svg`,
            alt: 'Fitbit logo',
          },
          description: t('FitbitSettings.description'),
          btnLabel: t('FitbitSettings.ButtonLabel'),
        }}
      />
      <DisconnectionModal
        actions={{
          onClose: closeModal,
          onBtnClick: () => disconnectFitbit(),
        }}
        state={{
          isOpen: isModalOpen,
          isError,
          isLoading,
        }}
        content={{
          heading: t('FitbitSettings.ModalHeading'),
          description: [
            t('FitbitSettings.ModalDescription-pt_1'),
            t('FitbitSettings.ModalDescription-pt_2'),
          ],
          error: t('FitbitSettings.failure'),
          btnLabel: t('FitbitSettings.ModalButtonLabel'),
          btnNotNowLabel: t('FitbitSettings.ModalButtonNotNow'),
        }}
      />
    </>
  );
};
