import { LessThanMedium, Medium } from '@justgiving/responsive';
import React, { FunctionComponent } from 'react';
import {
  Route as ReactRoute,
  Redirect,
  Switch,
  useLocation,
} from 'react-router-dom';

import { AsyncPageNotFound } from '../../pages/AsyncPage';
import { NavLinks } from '../Navigation/NavLinks';
import { PrivateRoute } from '../Routes/PrivateRoute';
import { Route } from '../../routes';
import config from '../../config';

export const Layout: FunctionComponent<LayoutProps> = ({ routes }) => {
  const { pathname } = useLocation();

  const routeComponents = routes
    .filter(x => !x.isFeatureDisabled)
    .map(({ path, component, isPrivate, isExact }) => {
      if (isPrivate) {
        return (
          <PrivateRoute key={path} path={path} exact={isExact}>
            {component}
          </PrivateRoute>
        );
      }

      return (
        <ReactRoute key={path} path={path} exact={isExact}>
          {component}
        </ReactRoute>
      );
    });

  const INDEX = '/';

  if (pathname === INDEX && !config.features.showProfilePage) {
    const [{ path: firstItemPath }] = routes.filter(x => !x.isFeatureDisabled);

    return (
      <>
        <Medium>
          <Redirect to={firstItemPath} />
        </Medium>
        <LessThanMedium>
          <div className="jg-background--white">
            <h1 className="jg-space-ptmd jg-space-phsm jg-space-pbsm qa-page__heading">
              Account &amp; preferences
            </h1>
            <nav className="jg-background--white navigation">
              <NavLinks routes={routes} />
            </nav>
          </div>
        </LessThanMedium>
      </>
    );
  }

  return (
    <>
      <Medium>
        <div className="container jg-space-mvlg">
          <div className="row">
            <div className="col-md-3">
              <nav className="jg-background--white navigation">
                <NavLinks routes={routes} />
              </nav>
            </div>
            <div className="col-md-9">
              <Switch>
                {routeComponents}
                <ReactRoute component={AsyncPageNotFound} />
              </Switch>
            </div>
          </div>
        </div>
      </Medium>
      <LessThanMedium>
        <Switch>
          {routeComponents}
          <ReactRoute component={AsyncPageNotFound} />
        </Switch>
      </LessThanMedium>
    </>
  );
};

interface LayoutProps {
  routes: Route[];
}
