import { Link } from 'react-router-dom';
import React from 'react';
import Svg from '@justgiving/svg';
import { chevronLeftSlimIcon } from '@justgiving/icons';
import { paths } from '../../constants';
import { useTranslation } from 'react-i18next';

interface SettingsLinkProps {
  path?: string;
  label?: string;
  className?: string;
}

const SettingsLink: React.FC<SettingsLinkProps> = ({
  path = paths.Index,
  label,
  className = '',
}) => {
  const { t } = useTranslation();
  return (
    <Link to={path} className={`nav__settings-link ${className}`}>
      <Svg
        markup={chevronLeftSlimIcon}
        className="nav__settings-link__icon jg-icon--small jg-display-flex"
      />
      <div>{label ?? t('common.profile')}</div>
    </Link>
  );
};

export default SettingsLink;
