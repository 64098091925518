import moment from 'moment';

export const isValidPostCode = value => {
  if (value) {
    return /^(GIR[ ]?0AA|((AB|AL|B|BA|BB|BD|BH|BL|BN|BR|BS|BT|CA|CB|CF|CH|CM|CO|CR|CT|CV|CW|DA|DD|DE|DG|DH|DL|DN|DT|DY|E|EC|EH|EN|EX|FK|FY|G|GL|GY|GU|HA|HD|HG|HP|HR|HS|HU|HX|IG|IM|IP|IV|JE|KA|KT|KW|KY|L|LA|LD|LE|LL|LN|LS|LU|M|ME|MK|ML|N|NE|NG|NN|NP|NR|NW|OL|OX|PA|PE|PH|PL|PO|PR|RG|RH|RM|S|SA|SE|SG|SK|SL|SM|SN|SO|SP|SR|SS|ST|SW|SY|TA|TD|TF|TN|TQ|TR|TS|TW|UB|W|WA|WC|WD|WF|WN|WR|WS|WV|YO|ZE)(\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}))|BFPO[ ]?\d{1,4})$/.test(
      value.toUpperCase(),
    );
  }
  return false;
};

export const isValidAUPostCode = value => {
  if (value) {
    return /^[0-9]{3,4}$/.test(value);
  }
  return false;
};

export const isValidZipCode = value => {
  if (value) {
    return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
  }
  return false;
};

export const trimWhiteSpace = value => {
  if (value) {
    return value.trim();
  }
  return null;
};

export const isValidDateOfBirth = (day, month, year) => {
  if (day && month && year) {
    const date = moment(
      `${parseInt(year)}-${parseInt(month)}-${parseInt(day)}`,
      'YYYY-M-D',
      true,
    );
    return date.isValid();
  }
};

// take from https://github.com/Sembiance/email-validator
export const isValidEmailAddress = email => {
  // eslint-disable-next-line
  const emailRegexp = /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-?\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

  if (!email) return false;

  if (email.length > 254) return false;

  if (!emailRegexp.test(email)) return false;

  const emailName = email.split('@')[0];
  const emailDomain = email.split('@')[1];

  if (emailName.length > 64) return false;

  return !emailDomain.split('.').some(part => part.length > 63);
};

export const isValidPasswordComplexity = value => {
  if (value) {
    return /^(?:(?=.*\W)(?=.*[a-zA-Z])(?=.*\d))/.test(value);
  }
  return false;
};

export const isValidPasswordLength = value => {
  return trimWhiteSpace(value) && value.length >= 12;
};

export const escapeRegex = value => {
  // eslint-disable-next-line no-useless-escape
  return value.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
};

export default {
  isValidPostCode,
  isValidAUPostCode,
  isValidEmailAddress,
  isValidZipCode,
  trimWhiteSpace,
  isValidPasswordLength,
  isValidPasswordComplexity,
  escapeRegex,
  isValidDateOfBirth,
};
