import PropTypes from 'prop-types';
import React from 'react';

export const renderField = ({
  input,
  type,
  disabled,
  autoComplete,
  ariaLabel,
  meta: { touched, error },
}) => {
  const name = input.name;
  const isTouched =
    name === 'PostCode' && input.value && input.value.length > 0
      ? true
      : touched;
  const className =
    isTouched && error
      ? 'jg-form-control jg-form-control--error'
      : 'jg-form-control';

  return (
    <div>
      <input
        {...input}
        type={type}
        className={className}
        autoComplete={autoComplete}
        disabled={disabled}
        aria-label={ariaLabel}
      />
      {isTouched && error && (
        <span className="jg-form-error-copy">{error}</span>
      )}
    </div>
  );
};

renderField.defaultProps = {
  disabled: false,
  autoComplete: 'off',
};

renderField.propTypes = {
  input: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  type: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  }).isRequired,
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
};

export default renderField;
