import { ActionTypes as types } from 'constants';

const initialState = {
  data: {
    mandates: [],
  },
  status: {
    isFetching: false,
    isLoaded: false,
    isCancelling: false,
    isCancelled: false,
  },
};

const cancelDirectDebit = (data, cancelId) => {
  return {
    mandates: data.mandates.filter(x => x.mandateId !== cancelId),
  };
};

const directDebit = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.FETCH_DIRECTDEBIT_PAYMENTS:
      return Object.assign({}, state, {
        status: {
          ...state.status,
          isFetching: true,
          isLoaded: false,
        },
      });
    case types.FETCH_DIRECTDEBIT_PAYMENTS_SUCCESS:
      return Object.assign(
        {},
        { data: action.payload },
        {
          status: {
            ...state.status,
            isFetching: false,
            isLoaded: true,
          },
        },
      );
    case types.FETCH_DIRECTDEBIT_PAYMENTS_FAILURE:
      return Object.assign({}, state, {
        status: {
          ...state.status,
          isFetching: false,
          isLoaded: false,
        },
      });
    case types.CANCEL_DIRECTDEBIT_PAYMENTS:
      return Object.assign({}, state, {
        status: {
          ...state.status,
          isCancelling: true,
          isCancelled: false,
        },
      });
    case types.CANCEL_DIRECTDEBIT_PAYMENTS_SUCCESS:
      return Object.assign(
        {},
        { data: cancelDirectDebit(state.data, action.payload) },
        {
          status: {
            ...state.status,
            isCancelling: false,
            isCancelled: true,
          },
        },
      );
    case types.CANCEL_DIRECTDEBIT_PAYMENTS_FAILURE:
      return Object.assign({}, state, {
        status: {
          ...state.status,
          isCancelling: false,
          isCancelled: false,
        },
      });
    default:
      return state;
  }
};

export default directDebit;
