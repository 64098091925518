import { APIerrorMessages, forms, ActionTypes as types } from '../../constants';
import { logError, submitFormToIam } from '..';
import { resetAppErrors, updatePostAppError } from './updateAppErrors';

import { auth } from '../../utils';

const log = { component: 'updatePassword' };

export default (values, isSuperUser) => dispatch => {
  const userGuid = auth.getAccessToken().guid;
  const userResource = `/users/${userGuid}/password/${
    isSuperUser ? 'override' : 'change'
  }`;

  const body = {
    userGuid,
    currentPassword: values.currentPassword,
    newPassword: values.newPassword,
    confirmNewPassword: values.retypePassword,
  };

  const onSuccess = () => {
    dispatch(resetAppErrors());
  };

  const onError = (error = {}) => {
    if (error !== APIerrorMessages.wrongCurrentPassword) {
      dispatch(logError(types.UPDATE_APP_STATUS_POST_ERROR, { ...log, error }));
    }
    dispatch(updatePostAppError(error));
  };

  return dispatch(
    submitFormToIam(forms.Password, userResource, userGuid, body, {
      onError,
      onSuccess,
    }),
  );
};
