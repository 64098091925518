import promiseRetry from 'promise-retry';
import { apiCallConfig } from 'constants';

const defaultOpts = {
  retries: apiCallConfig.allowedNumberOfReties,
  timeoutInMs: apiCallConfig.timeoutInMs,
};

export const httpCallWithTimeout = (createPromise, opts = defaultOpts) => {
  const timeout = new Error('Request timeout');
  timeout.response = new Response(JSON.stringify({}), {
    ok: false,
    status: 408,
    statusText: 'Request Timeout',
  });

  return Promise.race([
    createPromise(),
    new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(timeout);
      }, opts.timeoutInMs);
    }),
  ]);
};

export default (createPromise, opts = defaultOpts) =>
  promiseRetry(retry => httpCallWithTimeout(createPromise, opts).catch(retry), {
    retries: opts.retries,
    factor: 1,
    minTimeout: opts.timeoutInMs,
    maxTimeout: opts.timeoutInMs,
  });
