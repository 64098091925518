import config from '../config';
import { cookieNames } from '../constants';
import { createAuth } from '@justgiving/auth';

export default createAuth({
  documentOrReq: document,
  authCookieName: cookieNames.authCookieName,
  activeGroupCookieName: '',
  refreshAccessTokenUrl: config.iam.refreshAccessTokenUrl,
});
