export type Payments = {
  id: number;
  amount: string;
  giftAid: string;
  dateTime: string;
  currency: string;
  status: string;
};

export enum PaymentStatus {
  PENDING,
  ACTIVE,
  FAILED,
  CANCELLED,
}

export type Cause = {
  id: string;
  type: string;
  beneficiaryName: string;
  description: string | null;
  url: string;
  imageName: string;
};

type MonthlyDonation = {
  id: number;
  paymentMethod: string;
  cardEnding: string;
  cause: Cause;
  predictedNextPayment?: Payments;
  payments: Payments[];
  status: PaymentStatus;
};

type DirectDebit = {
  mandateId: number;
  amount: number;
  payments: Payments[];
  cause: Cause;
  predictedNextPayment?: Payments;
  accountEnding: string;
  status: PaymentStatus;
};

export type State = {
  app: {
    loginStatus: {
      isLoggedIn: boolean;
    };
    localeData: {
      locale: string;
      isoCountryCode: string;
    };
    user: {
      isLoaded: boolean;
      data: {
        HasFacebookCredentials: boolean;
        HasJustGivingCredentials: boolean;
        FirstName: string;
        LastName: string;
        EmailAddress: string;
      };
    };
    fitness: {
      isLoaded: boolean;
      data: {
        isConnected: boolean;
      };
    };
    recurringPayments: {
      data: {
        active: MonthlyDonation[];
        cancelled: MonthlyDonation[];
      };
      status: {
        isFetching: boolean;
        isLoaded: boolean;
        isCancelling: boolean;
        isCancelled: boolean;
      };
    };
    directDebits: {
      data: {
        mandates: DirectDebit[];
      };
      status: {
        isFetching: boolean;
        isLoaded: boolean;
        isCancelling: boolean;
        isCancelled: boolean;
      };
    };
  };
  globalAlert: {
    message: string;
    type: 'warning' | 'error' | 'success' | 'info';
  };
};
