import { forms, ActionTypes as types } from '../../constants';
import { logError, submitFormToProfile } from '..';
import { resetAppErrors, updatePostAppError } from './updateAppErrors';

import { auth } from '../../utils';

const log = { component: 'updateNotifications' };

export default values => dispatch => {
  const userGuid = auth.getAccessToken().guid;
  const canContact = Boolean(values.CanContact);
  const notificationsResource = 'user-status/consent';
  const body = { canContact };

  const onSuccess = () => {
    dispatch({
      type: types.UPDATE_NOTIFICATIONS,
      payload: canContact,
    });
    dispatch(resetAppErrors());
  };

  const onError = error => {
    dispatch(updatePostAppError(error));
    dispatch(logError(types.UPDATE_APP_STATUS_POST_ERROR, { ...log, error }));
  };

  return dispatch(
    submitFormToProfile(
      forms.Notifications,
      notificationsResource,
      userGuid,
      body,
      { clearForm: false, onSuccess, onError },
    ),
  );
};
