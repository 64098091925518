import { submitForm } from 'actions';
import { profile } from 'constants';

export default (formName, resourcePath, userGuid, body, callerOptions = {}) =>
  submitForm(
    formName,
    `${profile.baseUrl}/${resourcePath}`,
    userGuid,
    body,
    callerOptions,
  );
