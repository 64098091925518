/* eslint-disable jsx-a11y/no-static-element-interactions, react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import { paths } from 'constants';
import { track } from 'utils/koala-analytics';
import { Trans } from 'react-i18next';

const propTypes = {
  dateOfBirth: PropTypes.string,
};

const defaultProps = {
  dateOfBirth: undefined,
};

const trackEditDateOfBirth = () => {
  const trackingData = {
    event: 'link click',
    subtype: 'hyperlink',
    page_section: 'personal details > date of birth',
    event_value: 'edit',
  };
  track(trackingData);
};

const DateOfBirth = props => (
  <section className="details-block dateOfBirth">
    <div className="jg-space-phsm@sm jg-space-phn@lg">
      <div className="jg-text--medium-light">
        <Trans i18nKey="personalDetails.dateOfBirth">Date of Birth</Trans>
        <br />
      </div>
      <p className="jg-text--dark">
        <span className="data-value">{props.dateOfBirth}</span>
        <Link
          to={paths.DateOfBirth}
          onClick={trackEditDateOfBirth}
          className="jg-edit-link qa-link__dateOfBirth jg-space-mlsm"
        >
          <Trans i18nKey="personalDetails.edit">Edit</Trans>
        </Link>
      </p>
    </div>
  </section>
);

DateOfBirth.propTypes = propTypes;

DateOfBirth.defaultProps = defaultProps;

export default DateOfBirth;
