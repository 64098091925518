import React, { FunctionComponent, useState } from 'react';
import { useDispatch } from 'react-redux';
import Modal from '@justgiving/modal';
import Loading from '@justgiving/loading';
import Alert from '@justgiving/alert';
import classnames from 'classnames';
import { useTranslation, Trans } from 'react-i18next';

import { image as imageUrl } from '../../constants';
import { getCurrencySymbol, getCurrencyLocale } from '../../utils';
import { trackEventExtended } from '../../actions/koalaAnalyticsActions';
import {
  setGlobalAlert,
  removeGlobalAlert,
} from '../GlobalAlert/GlobalAlert.actions';
import cancelRecurringPayments from '../../actions/setters/cancelRecurringPayments';

export type ModalProps = {
  cause: any;
  amount?: string;
  currency?: string;
  giftAid?: string;
  id: number;
  close: () => void;
};

const RecurringCardPaymentsModal: FunctionComponent<ModalProps> = ({
  cause,
  amount,
  currency,
  giftAid,
  id,
  close,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isCancelLoading, setIsCancelLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const giftAidDisplay = `+ ${getCurrencySymbol(currency)}${getCurrencyLocale(
    giftAid,
  )} ${t('common.giftAid')}`;
  const donationAmount = `
  ${getCurrencySymbol(currency)}${getCurrencyLocale(amount)}
  ${giftAid ? giftAidDisplay : ''}
  `;
  const trackingData = {
    actor_id: id,
    actor_sources: 'donation_guid',
    content_id: cause.id,
    content_type:
      cause.type === 'CharityDirect' ? 'charity_id' : 'crowdfunding_guid',
    card_id: amount,
    card_url: 'donation amount',
    event: 'link click',
    subtype: 'button',
    page_section: 'monthly donations > cancel',
    page_type: 'end user account',
  };
  const dontCancelClickHandler = () => {
    trackEventExtended({
      ...trackingData,
      event_value: 'no',
    })(dispatch);
    close();
  };
  const cancelClickHandler = async () => {
    trackEventExtended({
      ...trackingData,
      event_value: 'yes',
    });
    setIsCancelLoading(true);
    setIsError(false);
    try {
      dispatch(removeGlobalAlert());
      await dispatch(cancelRecurringPayments(id));
      close();
      dispatch(setGlobalAlert(t('recurringCard.globalSuccess')));
    } catch {
      setIsError(true);
      setIsCancelLoading(false);
    }
  };
  const modalClasses = classnames({
    'recurring-payment-modal': true,
    'recurring-payment-modal--error': isError,
  });
  const { beneficiaryName } = cause;
  return (
    <Modal className={modalClasses} isOpen onAfterClose={close}>
      <div className="recurring-payment-modal__container">
        <div
          className={`recurring-payment-item__cause-${
            cause.type === 'CharityDirect' ? 'charity-logo' : 'crowdfunder-img'
          }`}
        >
          <a href={cause.url}>
            <img
              src={
                cause.imageName
                  ? `${imageUrl.baseUrl}${cause.imageName}`
                  : imageUrl.defaultAvatar
              }
              alt=""
            />
          </a>
        </div>

        <h2 className="recurring-payment-modal__cause-header">
          {t('recurringCard.modal.areYouSure')}
        </h2>
        <p>
          <Trans i18nKey="recurringCard.modal.description">
            If you confirm cancellation <strong>{{ beneficiaryName }}</strong>
            &nbsp;will no longer receive your
            <strong className="jg-color--primary">
              {' '}
              {{ donationAmount }}
            </strong>{' '}
            monthly donation.
          </Trans>
        </p>
        <div>
          <button
            type="button"
            className="jg-btn jg-btn--brand recurring-payment-modal__button"
            onClick={dontCancelClickHandler}
          >
            {t('recurringCard.modal.noKeepMyDonation')}
          </button>
          {isError && (
            <Alert
              displayType="inline"
              type="warning"
              className="jg-space-mvsm"
            >
              {t('common.tryAgain')}
            </Alert>
          )}
          <button
            type="submit"
            className="jg-btn jg-btn--ghost recurring-payment-modal__button"
            onClick={cancelClickHandler}
            disabled={isCancelLoading}
          >
            {isCancelLoading ? (
              <Loading size="small" colour="purple" />
            ) : (
              t('recurringCard.modal.yesCancelMyDonation')
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default RecurringCardPaymentsModal;
