import { captureException } from '@sentry/browser';
import config from '../config';

const componentDidCatch = error => {
  if (config.sentry) {
    captureException(error);
  } else {
    console.log(error); // eslint-disable-line no-console
  }
};

export default componentDidCatch;
