import { checkLogin } from 'actions';
import { getBearerHeaders } from 'utils';
import withRetry from 'actions/api/withRetry';

export default url => dispatch =>
  dispatch(checkLogin()).then(() => {
    const fetchPromise = () =>
      fetch(url, {
        method: 'GET',
        headers: getBearerHeaders(),
        credentials: 'include',
      }).then(res => Promise.all([res, res.json()]));

    return withRetry(fetchPromise);
  });
