import {
  consentStatementTypes,
  forms,
  ActionTypes as types,
} from '../../constants';
import {
  getUserNotifications,
  logError,
  submitFormToPreferencesService,
} from '..';
import { resetAppErrors, updatePostAppError } from './updateAppErrors';

import { auth } from '../../utils';

const log = { component: 'updateNotifications' };

export default values => (dispatch, getState) => {
  const userGuid = auth.getAccessToken().guid;
  const notificationsResource = `users/${userGuid}/consents`;
  const body = values;
  const { consents } = getState().app.notifications;

  const onSuccess = () => {
    dispatch({
      type: types.UPDATE_USER_NOTIFICATIONS,
      payload: body,
    });

    const shouldGetUserNotifications = Object.keys(consents).find(
      i =>
        consents[i].consentStatementType ===
        consentStatementTypes.directMarketingEmail,
    );

    if (!shouldGetUserNotifications) {
      dispatch(getUserNotifications());
    }
    dispatch(resetAppErrors());
  };

  const onError = error => {
    dispatch(updatePostAppError(error));
    dispatch(logError(types.UPDATE_APP_STATUS_POST_ERROR, { ...log, error }));
  };

  return dispatch(
    submitFormToPreferencesService(
      forms.Notifications,
      notificationsResource,
      userGuid,
      body,
      {
        clearForm: false,
        isUpdate: true,
        isPreferencesService: true,
        onSuccess,
        onError,
      },
    ),
  );
};
