import appErrors from './appErrors';
import { combineReducers } from 'redux';
import consents from './consents';
import crtNotifications from './notifications';
import directDebits from './directDebit';
import donationHistory from './donationHistory';
import donationSummary from './donationSummary';
import { features } from '../constants';
import findAddresses from './findAddresses';
import localeData from './localeData';
import loginStatus from './loginStatus';
import paymentMethods from './paymentMethods';
import recurringPayments from './recurringPayments';
import user from './user';
import userAddress from './userAddress';
import userNotifications from './userNotifications';

const notifications = features.preferencesService
  ? userNotifications
  : crtNotifications;

export default combineReducers({
  loginStatus,
  user,
  userAddress,
  findAddresses,
  notifications,
  paymentMethods,
  localeData,
  recurringPayments,
  directDebits,
  appErrors,
  donationHistory,
  donationSummary,
  consents,
});
