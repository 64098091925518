import config from '../config';
import { useCallback } from 'react';

const useRedirectToCharityHome = () => {
  return useCallback(
    () => window.location.assign(`${config.charityHomeUrl}`),
    [],
  );
};

export default useRedirectToCharityHome;
