import { ActionTypes as types } from 'constants';
import { getFromIamApi, logError } from 'actions';
import { updateFetchAppError } from 'actions/setters/updateAppErrors';

const log = { component: 'findAddresses' };

export default (
  postCode,
  _getFromIamApi = getFromIamApi,
  _logError = logError,
) => dispatch => {
  dispatch({
    type: types.FETCH_FIND_ADDRESSES,
  });

  const findAddressesResource = `/address/find/${encodeURIComponent(postCode)}`;
  return dispatch(_getFromIamApi(findAddressesResource))
    .then(([res, json]) => {
      if (res.ok) {
        dispatch({
          type: types.FETCH_FIND_ADDRESSES_SUCCESS,
          payload: json.Result,
        });
      } else if (json && json.Errors && json.Errors.length > 0) {
        const error = json.Errors[0].ErrorMessage;
        dispatch(updateFetchAppError());
        dispatch(
          _logError(types.FETCH_FIND_ADDRESSES_FAILURE, { ...log, error }),
        );
      } else {
        dispatch(updateFetchAppError());
        dispatch(
          _logError(types.FETCH_FIND_ADDRESSES_FAILURE, { ...log, res }),
        );
      }
    })
    .catch(error => {
      dispatch(updateFetchAppError());
      dispatch(
        _logError(types.FETCH_FIND_ADDRESSES_FAILURE, { ...log, error }),
      );
    });
};
