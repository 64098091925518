import { getFromApi, logError } from 'actions';
import { recurringPayments, ActionTypes as types } from 'constants';

import { auth } from '../../utils';
import { updateFetchAppError } from 'actions/setters/updateAppErrors';

const log = { component: 'getRecurringPayments' };

export default (_logError = logError) => (dispatch, getState) => {
  const { isLoaded } = getState().app.recurringPayments.status;

  if (isLoaded) return Promise.resolve('isLoaded');

  dispatch({
    type: types.FETCH_RECURRING_PAYMENTS,
  });

  const recurringPaymentsResource = `${
    recurringPayments.baseUrl
  }/api/v2/recurring-payments/user/${auth.getAccessToken().guid}`;

  /* eslint-disable max-len */
  // MOCK endpoint - use if there are no recurring payments on your dev account
  // const recurringPaymentsResource = `${recurringPayments.baseUrl}/users/${Auth.getUserGuid()}/monthly-plans`;

  return dispatch(getFromApi(recurringPaymentsResource))
    .then(([res, json]) => {
      if (res.ok) {
        dispatch({
          type: types.FETCH_RECURRING_PAYMENTS_SUCCESS,
          payload: json.result,
        });
      } else if (json && json.errorSummary) {
        const error = json.errorSummary;
        dispatch(updateFetchAppError());
        dispatch({ type: types.FETCH_RECURRING_PAYMENTS_FAILURE });
        dispatch(
          _logError(types.UPDATE_APP_STATUS_FETCH_ERROR, { ...log, error }),
        );
      } else {
        dispatch(updateFetchAppError());
        dispatch({ type: types.FETCH_RECURRING_PAYMENTS_FAILURE });
        dispatch(
          _logError(types.UPDATE_APP_STATUS_FETCH_ERROR, { ...log, res }),
        );
      }
    })
    .catch(error => {
      dispatch(updateFetchAppError());
      dispatch({ type: types.FETCH_RECURRING_PAYMENTS_FAILURE });
      dispatch(
        _logError(types.UPDATE_APP_STATUS_FETCH_ERROR, { ...log, error }),
      );
    });
};
