import { forms, ActionTypes as types } from 'constants';
import { getFromIamApi, logError, updateCountryCode } from 'actions';

import { auth } from '../../utils';
import { initialize } from 'redux-form';
import { updateFetchAppError } from 'actions/setters/updateAppErrors';

const log = { component: 'getUserAddress' };

// eslint-disable-next-line max-len
export default (
  updateAddressForm,
  _getFromIamApi = getFromIamApi,
  _logError = logError,
) => (dispatch, getState) => {
  const state = getState();

  dispatch({
    type: types.FETCH_USER_ADDRESS,
  });

  const userAddressId = state.userAddress
    ? state.userAddress.userAddressId
    : undefined;
  const userGuid = auth.getAccessToken().guid;
  const userAddressResource = userAddressId
    ? `/users/${userGuid}/address/${userAddressId}`
    : `/users/${userGuid}/address`;

  return dispatch(_getFromIamApi(userAddressResource))
    .then(([res, json]) => {
      if (res.ok) {
        const payload = json.Result[0] || {};
        dispatch({
          type: types.FETCH_USER_ADDRESS_SUCCESS,
          payload,
        });

        if (payload.CountryCode) {
          dispatch({
            type: types.UPDATE_COUNTRY_CODE,
            payload: payload.CountryCode,
          });
        }

        if (updateAddressForm) {
          dispatch(initialize(forms.Address, payload));
          dispatch(updateCountryCode(payload.CountryCode));
        }
      } else if (json && json.Errors && json.Errors.length > 0) {
        const error = json.Errors[0].ErrorMessage;
        dispatch(updateFetchAppError());
        dispatch({ type: types.FETCH_USER_ADDRESS_FAILURE });
        dispatch(
          _logError(types.UPDATE_APP_STATUS_FETCH_ERROR, { ...log, error }),
        );
      } else {
        dispatch(updateFetchAppError());
        dispatch({ type: types.FETCH_USER_ADDRESS_FAILURE });
        dispatch(
          _logError(types.UPDATE_APP_STATUS_FETCH_ERROR, { ...log, res }),
        );
      }
    })
    .catch(error => {
      dispatch(updateFetchAppError());
      dispatch({ type: types.FETCH_USER_ADDRESS_FAILURE });
      dispatch(
        _logError(types.UPDATE_APP_STATUS_FETCH_ERROR, { ...log, error }),
      );
    });
};
