import './ErrorPage.scss';
import React from 'react';
import ErrorPage from '@justgiving/error-page';

const ErrorPageWrapper = () => (
  <div data-qa="app" className="jg-eua-container">
    <div className="jg-eua-errorcontent jg-space-phmd@lg jg-space-pvmd">
      <ErrorPage errorMessage="Page failed to render." status={500} />
    </div>
  </div>
);

export default ErrorPageWrapper;
