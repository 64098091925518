import { ActionTypes as types, consentStatementTypes } from 'constants';

const initialState = {
  allConsentStatements: [],
  consentStatements: [],
  userConsent: false,
  isOptionSelected: false,
  isLoaded: false,
  isFetching: false,
};

const consents = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.FETCH_CONSENT_STATEMENTS:
      return Object.assign({}, state, {
        isLoaded: false,
        isFetching: true,
      });
    case types.FETCH_CONSENT_STATEMENTS_SUCCESS:
      return Object.assign(
        {},
        {
          allConsentStatements: action.payload,
          consentStatements: action.payload.filter(
            consent =>
              consent.type === consentStatementTypes.directMarketingEmail,
          ),
        },
        {
          isLoaded: true,
          isFetching: false,
        },
      );
    case types.FETCH_CONSENT_STATEMENTS_FAILURE:
      return Object.assign({}, state, {
        isLoaded: false,
        isFetching: false,
      });
    case types.UPDATE_USER_CONSENT:
      return {
        ...state,
        userConsent: action.payload.consent.consentGiven,
        isOptionSelected: true,
        consentStatements: [action.payload.consent],
      };
    default:
      return state;
  }
};

export default consents;
