import { ActionTypes as types, consentStatementTypes } from 'constants';

const initialState = {
  data: {},
  consents: {},
  showConsentModal: false,
  isLoaded: false,
  isFetching: false,
};

const notifications = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.FETCH_USER_NOTIFICATIONS:
      return Object.assign({}, state, {
        isLoaded: false,
        isFetching: true,
      });
    case types.FETCH_USER_NOTIFICATIONS_SUCCESS:
      return Object.assign(
        {},
        {
          data: action.payload.json,
          consents: action.payload.consents,
        },
        {
          isLoaded: true,
          isFetching: false,
          showConsentModal: !Object.keys(action.payload.consents).find(
            i =>
              action.payload.consents[i].consentStatementType ===
              consentStatementTypes.directMarketingEmail,
          ),
        },
      );
    case types.FETCH_USER_NOTIFICATIONS_FAILURE:
      return Object.assign({}, state, {
        isLoaded: false,
        isFetching: false,
      });
    case types.UPDATE_USER_NOTIFICATIONS:
      return state;
    case types.CLOSE_CONSENT_MODAL:
      return {
        ...state,
        showConsentModal: false,
      };
    default:
      return state;
  }
};

export default notifications;
