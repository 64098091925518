import { ActionTypes as types } from 'constants';

export const resetAppErrors = () => dispatch => {
  dispatch({ type: types.RESET_APP_ERRORS });
};

export const updatePostAppError = error => dispatch => {
  dispatch({
    type: types.UPDATE_APP_STATUS_POST_ERROR,
    payload: error,
  });
};

export const updateFetchAppError = () => dispatch => {
  dispatch({
    type: types.UPDATE_APP_STATUS_FETCH_ERROR,
  });
};
