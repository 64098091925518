import { ActionTypes as types } from 'constants';

const initialState = {
  fetchError: false,
  postError: false,
  errorMessage: '',
};

const appErrors = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.UPDATE_APP_STATUS_FETCH_ERROR:
      return Object.assign({}, state, {
        fetchError: true,
        postError: false,
        errorMessage: 'Failed to fetch',
      });
    case types.UPDATE_APP_STATUS_POST_ERROR:
      return Object.assign({}, state, {
        fetchError: false,
        postError: true,
        errorMessage:
          action.payload && typeof action.payload === 'string'
            ? action.payload
            : 'Failed to post',
      });
    case types.RESET_APP_ERRORS:
      return Object.assign({}, state, {
        fetchError: false,
        postError: false,
        errorMessage: '',
      });
    default:
      return state;
  }
};

export default appErrors;
