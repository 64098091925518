/* eslint-disable global-require, no-use-before-define */
import './gtm';
import '@justgiving/accordion/src/Accordion.scss';
import '@justgiving/carepack-typography';
import './styles/app.scss';

import * as Sentry from '@sentry/browser';

import React, { Suspense } from 'react';

import App from './components/App';
import { AuthProvider } from '@justgiving/auth-react';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { PageComponentsProvider } from '@justgiving/page-components';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { auth } from './utils';
import config from './config';
import i18next from './i18next-config';
import { store } from './configureStore';

const { basename, sentry } = config;

if (sentry) {
  Sentry.init(sentry.config);
}

if (!window.Intl) {
  import('intl').then(_ => {
    render();
  });
} else {
  render();
}

function render() {
  ReactDOM.render(
    <Suspense fallback={<div />}>
      <I18nextProvider i18n={i18next}>
        <PageComponentsProvider>
          <AuthProvider auth={auth}>
            <Provider store={store}>
              <BrowserRouter basename={basename}>
                <App />
              </BrowserRouter>
            </Provider>
          </AuthProvider>
        </PageComponentsProvider>
      </I18nextProvider>
    </Suspense>,
    document.getElementById('root'),
  );
}
