import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { DonationHistoryLink } from '../components/Navigation';
import { DonationSummaryFormContainer } from '../components/DonationSummaryForm';
import PageHeader from '../components/PageHeader/PageHeader';

const propTypes = {
  match: {
    params: {
      id: PropTypes.string,
    },
  },
};

const DonationSummary = props => (
  <div className="wrapper-main">
    <DonationHistoryLink />
    <PageHeader>
      <h1>
        <Trans i18nKey="donationSummaryForm.donationSummary">
          Donation summary
        </Trans>
      </h1>
    </PageHeader>
    <DonationSummaryFormContainer donationId={props.match.params.id} />
  </div>
);

DonationSummary.propTypes = propTypes;

export default DonationSummary;
