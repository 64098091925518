import Alert from '@justgiving/alert';
import Loading from '@justgiving/loading';
import Modal from '@justgiving/modal';
import React from 'react';

interface DisconnectionModalProps {
  actions: {
    onClose: () => void;
    onBtnClick: () => void;
  };
  state: {
    isOpen: boolean;
    isError: boolean;
    isLoading: boolean;
  };
  content: {
    heading: string;
    description: string[];
    error: string;
    btnLabel: string;
    btnNotNowLabel: string;
  };
}

export function DisconnectionModal({
  actions: { onClose, onBtnClick },
  state: { isOpen, isError, isLoading },
  content: { heading, description, error, btnLabel, btnNotNowLabel },
}: DisconnectionModalProps) {
  return (
    <Modal
      className="disconnection-wrapper"
      classNames="jg-space-pxs"
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <div className="app-settings__modal">
        <h2 className="jg-text--center app-settings__modal__title">
          {heading}
        </h2>
        {description.map(paragraph => (
          <p className="jg-text jg-space-mvlg app-settings__modal__description">
            {paragraph}
          </p>
        ))}
        <div className="app-settings__modal__buttons">
          {isError && (
            <Alert
              displayType="inline"
              type="warning"
              className="jg-space-mbmd"
            >
              {error}
            </Alert>
          )}
          <button
            onClick={onBtnClick}
            className="jg-btn jg-btn--brand jg-space-mbsm"
            disabled={isLoading}
          >
            {isLoading ? <Loading size="small" colour="white" /> : btnLabel}
          </button>
          <button onClick={onClose} className="jg-btn jg-btn--ghost">
            {btnNotNowLabel}
          </button>
        </div>
      </div>
    </Modal>
  );
}
