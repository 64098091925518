import { ActionTypes as types, recurringPayments } from 'constants';
import checkLogin from '../setters/checkLogin';
import { logError } from 'actions';
import {
  resetAppErrors,
  updatePostAppError,
} from 'actions/setters/updateAppErrors';
import { getBearerHeaders } from '../../utils';

const log = { component: 'cancelDirectDebit' };

export default (id, _checkLogin = checkLogin, _logError = logError) => async (
  dispatch,
  _getState,
  auth,
) => {
  dispatch({
    type: types.CANCEL_DIRECTDEBIT_PAYMENTS,
  });
  const { guid: userGuid } = auth.getAccessToken();
  const headers = getBearerHeaders();
  const directDebitResource = `${recurringPayments.baseUrl}/api/v1/direct-debits/user/${userGuid}/mandate/${id}`;

  await dispatch(_checkLogin());

  try {
    const response = await fetch(directDebitResource, {
      method: 'DELETE',
      headers,
      body: JSON.stringify({}),
      credentials: 'include',
    });

    if (response.ok) {
      dispatch({
        type: types.CANCEL_DIRECTDEBIT_PAYMENTS_SUCCESS,
        payload: id,
      });
      dispatch(resetAppErrors());
    } else {
      dispatch({
        type: types.CANCEL_DIRECTDEBIT_PAYMENTS_FAILURE,
      });
      dispatch(updatePostAppError(res));
      dispatch(_logError(types.UPDATE_APP_STATUS_POST_ERROR, { ...log, res }));
    }
  } catch (error) {
    dispatch({ type: types.CANCEL_DIRECTDEBIT_PAYMENTS_FAILURE });
    dispatch(updatePostAppError(error));
    dispatch(_logError(types.UPDATE_APP_STATUS_POST_ERROR, { ...log, error }));
  }
};
