import { APIerrorMessages, forms, ActionTypes as types } from '../../constants';
import { logError, submitFormToIam } from '..';
import { resetAppErrors, updatePostAppError } from './updateAppErrors';

import { auth } from '../../utils';

const log = { component: 'updateEmail' };

export default values => dispatch => {
  const userGuid = auth.getAccessToken().guid;
  const userResource = `/users/${userGuid}/change-email-address`;
  const body = {
    userGuid,
    EmailAddress: values.EmailAddress,
  };

  const onSuccess = () => {
    dispatch({
      type: types.UPDATE_USER,
      payload: body,
    });
    dispatch(resetAppErrors());
  };

  const onError = (error = {}) => {
    if (error !== APIerrorMessages.emailAlreadyInUse) {
      dispatch(logError(types.UPDATE_APP_STATUS_POST_ERROR, { ...log, error }));
    }
    dispatch(updatePostAppError(error));
  };

  return dispatch(
    submitFormToIam(forms.Email, userResource, userGuid, body, {
      clearForm: false,
      onSuccess,
      onError,
    }),
  );
};
